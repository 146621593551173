import React from 'react';
import Neetdropdown from './Neetdropdown';

const Neet = () => {
  //neet Down Data Section Start
  const neet = [
    {
      title: 'Home',
      links: [
        { label: 'About Exam', url: '/about/about-neet-exam' },
        { label: 'Pattern Of Exam', url: '/about/pattern-of-neet-exam' },
        { label: 'Syllabus OF Exam', url: '/about/syllabus-of-neet-exam' },
        { label: 'Sample Notes', url: '/about/Sample-Notes-for-ias-exam' },
        { label: 'FAQs', url: '/about/frequently-asked-question-for-neet-exam' },
        // Add more links for the 'Home' category
      ]
    },
    {
      title: 'COURSES',
      links: [
        { label: '4-Year Foundation Course', url: '/4-year-foundation-Course' },
        { label: '3-Year Foundation Course', url: '/3-year-foundation-Course' },
        { label: '2-Year Foundation Course', url: '/2-year-foundation-Course' },
        { label: '1-Year Foundation Course', url: '/1-year-foundation-Course' },
        // Add more links for 'COURSES' category
      ]
    },
    {
      title: 'TEST SERIES',
      links: [
        { label: 'Physics Test Series', url: '/physics-test-series' },
        { label: 'Chemistry Test Series', url: '/chemistry-test-series' },
        { label: 'Biology Test Series', url: '/biology-test-series' },
        { label: 'Integrated Test Series', url: '/integrated-test-series' },
        // Add more links for 'Test SERIES' category
      ]
    }
  ];
  //neet Drop Down Data Section End

  return (
    <div>
      <Neetdropdown neet={neet} />
    </div>
  );
};

export default Neet;
