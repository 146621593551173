import React, { useEffect } from 'react';
import "./Slider.css";
import logo1 from "../../Image/GAURAV-AGRAWAL.jpg";
import logo2 from "../../Image/FAIZ-AHMAD-MUMTAZ.jpg";
import logo3 from "../../Image/MALLIKA SUDHIR.jpg";
import logo4 from "../../Image/PRADEEP-SINGH.png";
import logo5 from "../../Image/SHWETA-CHAUHAN.jpg";

function Slider() {
    // function for multiple card
    useEffect(() => {
        const Doctoritems = document.querySelectorAll('.carousel-laptop .item-laptop');
        Doctoritems.forEach((el) => {
            const minPerSlide = 4;
            let next = el.nextElementSibling;
            for (let i = 1; i < minPerSlide; i++) {
                if (!next) {
                    // wrap carousel by using first child
                    next = Doctoritems[0];
                }
                let cloneChild = next.cloneNode(true);
                el.appendChild(cloneChild.children[0]);
                next = next.nextElementSibling;
            }
        });
    }, []);
    return (
        <>
            {/* Carsousel Section Start */}
            <div className='Carsousel_Container mb-3'>
                <div className='Carousel_heading'>
                    <h1>Check out what IAS Toppers Have to say...</h1>
                </div>
                <div className='Carousel_Slider'>
                    <div id="carouselExampleControl" className="carousel slide carousel-laptop" data-bs-ride="carousel">
                        <div className="carousel-inner inner-laptop">
                            <div className="carousel-item item-laptop active Carousel-box me-3">
                                <div className='col-md-6 '>
                                    <div className="card text-center Slider-Container">
                                        <img src={logo1} className="card-img-top rounded-circle img-size" alt="..." />
                                        <div className="card-body">
                                            <h5 className="card-title">Gaurav Agrawal</h5>
                                            <p className="card-text">Rank 1, IAS-2013</p>
                                            <p className="card-text">Baliyan sir's INSIGHT is the only classroom coaching I found good and upto UPSC standards. I also liked sir's approach. I joined history optional coaching here and benefitted immensely.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item item-laptop Carousel-box me-3">
                                <div className='col-md-6 '>
                                    <div className="card text-center Slider-Container">
                                        <img src={logo2} className="card-img-top rounded-circle img-size" alt="..." />
                                        <div className="card-body">
                                            <h5 className="card-title">Faiz Ahmad Mumtaz</h5>
                                            <p className="card-text">Rank 1, IAS-2014</p>
                                            <p className="card-text">
                                                I had joined INSIGHT IAS ACADEMY of S. Baliyan Sir which played a crucial role in my success in civil services.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item item-laptop Carousel-box me-3">
                                <div className='col-md-6'>
                                    <div className="card text-center Slider-Container">
                                        <img src={logo5} className="card-img-top rounded-circle img-size" alt="..." />
                                        <div className="card-body">
                                            <h5 className="card-title">Shweta Chauhan</h5>
                                            <p className="card-text">Rank 8, IAS-2016</p>
                                            <p className="card-text">I joined Insight Academy of S. Baliyan Sir at Delhi. Classes and personal guidance of Baliyan sir were very useful in providing direction and in maintaining focus during my preparation.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item item-laptop Carousel-box me-3">
                                <div className='col-md-6'>
                                    <div className="card text-center Slider-Container">
                                        <img src={logo4} className="card-img-top rounded-circle img-size" alt="..." />
                                        <div className="card-body">
                                            <h5 className="card-title">Pradeep Singh</h5>
                                            <p className="card-text">Rank 1, IAS-2019</p>
                                            <p className="card-text">
                                                General Studies and History optional classes at Insight Academy of S. Baliyan Sir is are perfectly in tune with the changing pattern of UPSC examination.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item item-laptop Carousel-box me-3">
                                <div className='col-md-6'>
                                    <div className="card text-center Slider-Container">
                                        <img src={logo3} className="card-img-top rounded-circle img-size" alt="..." />
                                        <div className="card-body">
                                            <h5 className="card-title">Malika Sudhir</h5>
                                            <p className="card-text">Rank 26, IAS-2016</p>
                                            <p className="card-text">
                                                I joined Insight Academy of S. BALIYAN Sir for History Optional at Delhi. It played a very crucial role in my success in Civil Services Examination.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControl" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControl" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* Carsousel Section End */}
        </>
    )
}

export default Slider;

