import React from 'react';
import "./Footer.css";
import { IoIosArrowRoundForward } from "react-icons/io";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { Link } from 'react-router-dom';

function Footer() {
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
    return (
        // Footer Section Start
        <div>
            <div className='FooterContainer' id='footer' onClick={handleScrollToTop}>
                <div className='FooterBox'>
                    <div className='Foot1'>
                        <section>
                            <h1>COMPANY</h1>
                        </section>
                        <ul className='ps-0'>
                            <li className=''>
                                <Link className='list-group-item' to="/">
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link className='list-group-item' to="/about-us">
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    About Us
                                </Link>
                            </li>
                            <li>
                                <Link className='list-group-item' to="/terms-and-conditions">
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    Terms and Conditions
                                </Link>
                            </li>
                            <li>
                                <Link className='list-group-item' to='/contact-us'>
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    Contact Us
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='Foot2'>
                        <section>
                            <h1>CIVILS</h1>
                        </section>
                        <ul className='ps-0'>
                            <li>
                                <Link className='list-group-item' to="/civils">
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    Courses
                                </Link>
                            </li>
                            <li>
                                <Link className='list-group-item' to="/civilstestseries">
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    Test Series
                                </Link>
                            </li>
                            <li>
                                <Link className='list-group-item' to="/daily-current-affairs/News">
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    Current Affairs
                                </Link>
                            </li>
                            <li>
                                <Link className='list-group-item' to="/about/frequently-asked-questions-about-ias-exam">
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    FAQs
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='Foot3'>

                        <section>
                            <h1>NEET</h1>
                        </section>
                        <ul className='ps-0'>
                            <li>
                                <Link className='list-group-item' to="/about/about-neet-exam">
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    About Exam
                                </Link>
                            </li>
                            <li>
                                <Link className='list-group-item' to="/about/pattern-of-neet-exam">
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    Pattern of Exam
                                </Link>
                            </li>
                            <li>
                                <Link className='list-group-item' to="/about/frequently-asked-question-for-neet-exam">
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    FAQs
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='Foot4'>
                        <section>
                            <h1>CUET</h1>
                        </section>
                        <ul className='ps-0'>
                            <li className='ps-0'>
                                <Link className='list-group-item ps-0'>
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link className='list-group-item'>
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    Common Aptitude Tests
                                </Link>
                            </li>
                            <li>
                                <Link className='list-group-item'>
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    Domain Specific Tests
                                </Link>
                            </li>
                            <li>
                                <Link className='list-group-item'>
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    FAQs
                                </Link>
                            </li>
                        </ul>

                    </div>
                    <div className='Foot5'>
                        <div className='ps-0'>
                            <section>
                                <h1>FOLLOW US</h1>
                            </section>
                            <ul>
                                <li>
                                    For Instant updates
                                </li>
                                <li className='mt-2 d-flex'>
                                    <Link to='/' style={{ borderRadius: "30px", width: "40px", padding: "0.3vmax", fontSize: "18px", height: "40px", display: "flex", justifyContent: "center", alignItems: "center" }} className='bg-icon1 text-white mx-2'>
                                        <FaInstagram />
                                    </Link>
                                    <Link to='/' style={{ borderRadius: "30px", width: "40px", padding: "0.3vmax", fontSize: "18px", height: "40px", display: "flex", justifyContent: "center", alignItems: "center" }} className='bg-icon2 text-white mx-2'>
                                        <FaFacebookF />
                                    </Link>
                                    <Link to='https://www.youtube.com/@INSIGHTACADEMYIAS' style={{ borderRadius: "30px", width: "40px", padding: "0.3vmax", fontSize: "18px", height: "40px", display: "flex", justifyContent: "center", alignItems: "center" }} className='bg-icon3 text-white mx-2'>
                                        <FaYoutube />
                                    </Link>
                                    <Link to='https://t.me/INSIGHTICS' style={{ borderRadius: "30px", width: "40px", padding: "0.3vmax", fontSize: "18px", height: "40px", display: "flex", justifyContent: "center", alignItems: "center" }} className='bg-icon4 text-white mx-2'>
                                        <FaTelegramPlane />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='Footermiddle'>
                    <p className='text-white'><Link className='text-white text-decoration-none'>GS Foundation Course</Link>, <Link className='text-white text-decoration-none'>History Optional (Online)</Link>, <Link className='text-white text-decoration-none'>History Optional (Offline)</Link>, <Link className='text-white text-decoration-none'>GS History & Culture</Link>, <Link className='text-white text-decoration-none'>Prelims Test Series 2023</Link>, <Link className='text-white text-decoration-none'>Pre-Cum-Mains Test Series 2023</Link>, <Link className='text-white text-decoration-none'>Mains Test Series 2022</Link>, <Link className='text-white text-decoration-none'>History Optional Test Series 2023</Link></p>
                </div>
                <div className='footerCopy'>
                    <h1 className='fs-6 text-white fw-lighter'>© 2023-33 Insight Edutech Pvt Ltd. All rights reserved.</h1>
                </div>
            </div>
        </div>
        // Footer Section End
    )
}

export default Footer;