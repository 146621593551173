import React from 'react';
import { Helmet } from 'react-helmet-async';
import "../../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection.css";
import CivilTopSection from '../../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection';
import userImage from "../../../Image/user.png";
import aboutImage from "../../../Image/aboutimg.png";
import CivilrightSection from '../../ReusableComponent/CIVIL/CivilrightSection/CivilrightSection';
import Content from '../../AllPageContent/AllPageContent';
import ButtonPage from '../../ButtonPage/ButtonPage';
import CurrentAffair from '../../CurrentAffair/CurrentAffair';

function Civilabout() { 
  const Buttonname = "Multiplebutton";
  const componentName = "currentaffairs";

  // Banner Content For CivilAbout Page
  const data = {
    academyName: 'Insight Academy, Delhi',
    userImage: userImage,
    userName: 'Priya Rathore',
    Image: aboutImage
  };

  // Content For CivilAbout Page
  const paragraphstatic = [
    {
      text: "INSIGHT ACADEMY was established in 2005 by Shri S. Baliyan to make available quality guidance and mentorship to serious aspirants preparing for UPSC, CUET, NEET and UGC-NET Exam. Ever since coming into existence INSIGHT ACADEMY has established itself as the best coaching institute for competitive exams in India.",
      styles: [
        { word: 'INSIGHT ACADEMY', style: 'font-weight: bold;' },
        { word: 'Shri S. Baliyan', style: 'font-weight: bold;' }
      ]
    },
    {
      text: "Shri S. Baliyan, Founder-Director, is one of the most successful and reputed faculties known in the field of UPSC preparation. He is an expert in the arena of teaching History and Art and Culture, and his lectures and guidance are the secret to the success of countless IAS toppers. He has also authored a book ‘A Compendium of Indian Art & Culture’, published by Oxford Press.",
      styles: [
        { word: 'Shri S. Baliyan', style: 'font-weight: bold;' },
        { word: 'History and Art and Culture', style: 'font-weight: bold;' },
        { word: '‘A Compendium of Indian Art & Culture’', style: 'font-weight: bold; color: #C00000;' }
      ]
    },
    {
      text: "INSIGHT ACADEMY is proud of the fact that its students like Mallika Sudhir, Gaurav Agarwal, Ashish Kumar, Jagrati Awasthi, Ankita Jain, Satyam Gandhi, Yash Jaluka, Tejasavi Nayak, Rashmita Panda, Mir Muhammad Ali and Anshul Gupta etc., have topped the UPSC Civil Services Examination. are serving the nation with great devotion and honesty. Their commitment and honesty reflect the values imparted by INSIGHT. It is a matter of great satisfaction that out of total about 770 districts in India, INSIGHT students are running more than 150 districts today as District Collectors.",
      styles: [
        { word: 'proud of the fact that its students', style: 'font-weight: bold;' },
        { word: 'topped the UPSC Civil Services Examination', style: 'font-weight: bold;' }
      ]
    },
    {
      text: "Insight Academy, Delhi follows a holistic learning approach to ensure best quality education and guidance to its students. Maximum attention is paid to build solid foundations through concept building classes and regular interaction with Mentors. Seminars and lectures of senior civil servants and former UPSC members are organised regularly to keep the aspirants motivated and to ensure that they imbibe the ethics of Indian Civil Services.",
      styles: [
        { word: 'holistic learning approach', style: 'font-weight: bold;' },
        { word: 'Maximum attention', style: 'font-weight: bold;' },
        { word: 'build solid foundations', style: 'font-weight: bold;' },
        { word: 'concept building', style: 'font-weight: bold;' },
        { word: 'regular interaction', style: 'font-weight: bold;' }
      ]
    },
    {
      text: "With the changing pattern of exam, INSIGHT focuses on conducting regular practice tests. Without answer writing skills it is very difficult to score good marks in Mains Examination and writing skills can be developed only gradually. Every class at INSIGHT is followed by important questions on topic covered and students are encouraged to write as many questions as possible.",
      styles: [
        { word: 'conducting regular practice tests', style: 'font-weight: bold;' }
      ]
    },
    {
      text: "Since the trend of questions being asked by UPSC, CUET, NEET & UGC-NET is changing rapidly, INSIGHT pays special attention to classes on current national and international developments. Without being in touch with latest happening in the world around us, it is almost impossible to crack these competitive exams. INSIGHT will equip you with the most updated knowledge to keep ahead of others.",
      styles: [
        { word: 'classes on current national and international developments', style: 'font-weight: bold;' }
      ]
    },
    {
      text: "Dear Aspirants, while joining INSIGHT always remember that you are in safest hands. Your success in IAS examination is our only motto because Your Success is the Success of INSIGHT.",
      styles: [
        { word: 'Your Success is the Success of INSIGHT.', style: 'color: #C00000; font-weight: bold;' }
      ]
    },
    {
      text: "MISSION",
      styles: [
        { word: 'MISSION', style: 'font-weight: bold; text-align: center; display: block;' }
      ]
    },
    {
      text: "The mission of Insight Academy is to nourish your Success, to get you ‘Exam-ready’. INSIGHT Focuses diligently on preparing its candidates thoroughly to face the toughest competitive examinations in the world with the confidence to say, “Yes, I can do it.”",
      styles: [
        { word: '‘Exam-ready’', style: 'font-weight: bold; color: #C00000;' },
        { word: '“Yes, I can do it.”', style: 'font-weight: bold; color: #C00000;' }
      ]
    },
    {
      text: "And after going through our programmes, INSIGHT guarantees you’ll find yourself in that position. Remember You Must Study Smart!",
      styles: [
        { word: '', style: 'font-weight: bold;' }
      ]
    },
    {
      text: "VISION",
      styles: [
        { word: 'VISION', style: 'font-weight: bold; text-align: center; display: block;' }
      ]
    },
    {
      text: '"Success is like a journey and the teacher is your navigation. Right guidance not only shortens the journey of success but also makes it pleasurable". – Shri S. Baliyan Sir',
      styles: [
        { word: 'Success is like a journey and the teacher is your navigation. Right guidance not only shortens the journey of success but also makes it pleasurable". – Shri S. Baliyan Sir', style: 'color: #C00000; font-weight: bold;' },
        // Add more styles as needed
      ]
    },
    {
      text: "Contact Us:",
      styles: [
        { word: 'Contact Us:', style: 'font-weight: bold;' }
      ]
    },
    {
      text: "Ph: 011- 45090051, 9818333201",
      styles: [
        { word: 'Ph:', style: 'font-weight: bold;' }
      ]
    },
    {
      text: "Email: support@insightdelhi.com",
      styles: [
        { word: 'Email:', style: 'font-weight: bold;' }
      ]
    },
    {
      text: "Address: 73, Bada Bazar Market, Old Rajinder Nagar, Rajinder Nagar, New Delhi, 110060",
      styles: [
        { word: 'Address:', style: 'font-weight: bold;' }
      ]
    },
  ];
 
  return (
    <>
      <Helmet>
        <title>About Us | Insight IAS, Delhi | Best IAS Coaching in Delhi</title>
        <meta name="description" content="Insight Academy Delhi, is the leading platform in India that provides coaching for candidates preparing for UPSC, IAS, CUET, NEET, UGC-NET examinations."></meta>
        <meta name="keywords" content="about insight ias, about us, in sight ias, insight ias, Baliyan Sir, balyan sir, baliyan sir history, history optional by baliyan sir, baliyan sir notes, history notes, insight ias delhi, insight ias india, insightsonindia, insights IAS, insight delhi, insight ias test series, insight test series, insight ias main test series, insight prelims test series, upsc coaching in delhi, civil services coaching in delhi, neet coaching center in delhi, ugc net coaching in delhi, CUET coaching in delhi, CUET form 2022, how to apply for CUET, CUET 2022, CUET Exam, Pattern of CUET Exam, Syllabus of CUET Exam, NEET Practice Tests."></meta>
        <meta name="subject" content="Insight Academy Delhi, is the leading platform in India that provides coaching for candidates preparing for UPSC, IAS, CUET, NEET, UGC-NET examinations."></meta>
      </Helmet>
      {/* Civil About Section Start */}
      <section className='civilabout'>
        {/* Civil Top Section Start */}
        <CivilTopSection
          academyName={data.academyName}
          userImage={data.userImage}
          userName={data.userName}
          Image={data.Image}
        />
        {/* Civil Top Section End */}
      </section>
      <div className='Civil-section'>
        <div className='civil-row'>
          <div className='col-lg-8 col-md-12 col-sm-12 me-2'>
            {/* Content Component Start */}
            <Content paragraphstatic={paragraphstatic} />
            {/* Content Component End */}
            <ButtonPage Pagename={Buttonname} />
          </div>
          {/* Civil right Section Start */}
          <div className='col-lg-4 col-md-12 col-sm-12 card'>
            <div>
              <CivilrightSection />
              </div>
              <div className="card-body">
                <CurrentAffair componentName={componentName} />
              </div>
          </div>
          {/* Civil right Section End */}
        </div>
      </div>
      {/* Civil About Section End */}
    </>
  )
}

export default Civilabout;