import axios from 'axios';
import { BASE_URL } from '../../insightConfig/insightConfig';

export async function getBookData(titleURL) {
    try {
        const courseResponse = await axios.get(BASE_URL + `/v2/books/${titleURL}`)
        return courseResponse.data.bookData;
    } catch (error) {
        console.log(error)
    }
}

export async function getBookTestData(packageId) {
    try {
        const response = await axios.get(BASE_URL + `/v2/getBookTestData/${packageId}`)
        return response.data.test;
    } catch (error) {
        console.log(error)
    }
}

export const rules =`<p style="text-align: justify;">Timer starts as soon as you click on&nbsp;<strong>'Start' button.</strong>&nbsp;You have to&nbsp;<strong>complete the test within the specified time</strong>&nbsp;and no extra time is allowed.&nbsp;</p>
<p style="text-align: justify;">You can&nbsp;<strong>attempt the test one more time after finishing your first attempt</strong>. Once you click on the&nbsp;<strong>'Finish' button,</strong>&nbsp;a pop-up box will appear. If you click on 'Submit' button on the box, no changes can be done after it.&nbsp;</p>
<p style="text-align: justify;">Once you select an option of Question,&nbsp;<strong>&lsquo;Clear&rsquo; button appears</strong>. Use this button to erase a chosen option.&nbsp;</p>
<p style="text-align: justify;">This test&nbsp;<strong>consists of 100 questions only in English</strong>. Each item comprises four responses. You have to select only one response. In case you feel that there is more than one correct response, mark the response which you consider the best.&nbsp;</p>
<p style="text-align: justify;">There is&nbsp;<strong>negative marking for incorrect answers</strong>. The&nbsp;<strong>penalty for incorrect answer is one-third of the marks allotted to that question</strong>.&nbsp;</p>
<p style="text-align: justify;">Mark only one option in the answer sheet for each question. If you mark more than one answer, then the question will be deemed incorrect even if one of the answer options you marked happens to be the correct answer.&nbsp;</p>
<p style="text-align: justify;">If a question is left unanswered, i.e., no option is marked, then no marks will be deducted for that question.</p>` 
