import React, { useEffect, useState } from 'react';
import "./CivilTopSection.css";
// import { IoLogoFacebook } from "react-icons/io5";
// import { FaTelegram } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { BiNews } from "react-icons/bi";
import defaultNewsImage from './newsImage.png'



// Reusable component for displaying user information
function UserInfo({ userImage, userName }) {
  return (
    <div className='written'>
      <div className='written-Img'>
        <img src={userImage} alt='...' className='userimg' />
      </div>
      <div className='written-heading ms-3 pt-2'>
        <h5 className='fs-6 text-white'>Written By</h5>
        <h3 className='fs-6 fw-bold text-white'>{userName}</h3>
      </div>
    </div>
  );
}

// Reusable component for the Civil Top section
function CivilTopSection({ academyName, academyName1, userImage, userName, Image }) {

  console.log(academyName1)
  const [firstHalf, setFirstHalf] = useState('')
  const [secondHalf, setSecondHalf] = useState('')
  const [thirdHalf, setThirdHalf] = useState('')

  function splitStringIntoThreeParts(inputString) {
    const length = inputString.length;
    const partLength = Math.floor(length / 3); // Approximate length for each part
    const parts = [];

    let startIndex = 0;
    let endIndex = partLength;

    for (let i = 0; i < 2; i++) { // Loop for the first two parts
      // Find the index to split the string without breaking a word
      while (inputString[endIndex] !== ' ' && endIndex > startIndex) {
        endIndex--;
      }
      parts.push(inputString.substring(startIndex, endIndex).trim());
      startIndex = endIndex + 1;
      endIndex = startIndex + partLength;
    }

    // Last part includes the remaining string
    parts.push(inputString.substring(startIndex).trim());

    return parts;
  }

  useEffect(() => {
    if (academyName1 && !academyName) {
      const parts = splitStringIntoThreeParts(academyName1);
      setFirstHalf(parts[0]);
      setSecondHalf(parts[1]);
      setThirdHalf(parts[2]);
    }
  }, [academyName1, academyName])


  return (
    <div className='civilTop-container'>
      <div className='CivilTop-left d-flex flex-column justify-content-around'>
        {/* {academyName && <h1 className='fs-2 py-0 pt-0 text-white'>{academyName}</h1>}
        {firstHalf && <h1 className='fs-2 py-0 pt-0 text-white'>{firstHalf}</h1>}
        {secondHalf && <h1 className='fs-2 py-0 pt-0 text-white'>{secondHalf}</h1>}
        {thirdHalf && <h1 className='fs-2 py-0 pt-0 text-white'>{thirdHalf}</h1>} */}
        {academyName1 && <h1 className='fs-2 py-0 pt-0 text-white'>{academyName1}</h1>}
        <UserInfo userImage={userImage} userName={userName} />
      </div>
      <div className='CivilTop-right'>
        {/* <BiNews color='red' size={200}/> */}
        <img src={Image} alt='...' onError={(e) => {
          e.target.src = defaultNewsImage;
        }} className='civilTop-img mt-2 ' />
        <div className='mt-0 pt-0 text-center text-white d-flex justify-content-end align-items-center'>
          <h5 className='fs-6 pt-0 me-2'>Follow us:</h5>
          {/* <FaInstagram className='fs-5 m-1' />
            <FaFacebookF className='fs-5 m-1' />
            <FaYoutube className='fs-5 m-1' />
            <FaTelegramPlane className='fs-5 m-1' /> */}
          <div className='d-flex justify-content-center  pt-0 mt-0'>
            <Link to='#' className='text-white fs-5 m-1  pt-0 mt-0' target='_blank'>
              <FaInstagram />
            </Link>
            <Link to='#' className='text-white fs-5 m-1 pt-0 mt-0' target='_blank'>
              <FaFacebookF />
            </Link>
            <Link to='https://www.youtube.com/@INSIGHTACADEMYIAS' target='_blank' className='text-white fs-5 m-1 pt-0 mt-0'>
              <FaYoutube />
            </Link>
            <Link to='https://t.me/INSIGHTICS' target='_blank' className='text-white fs-5 m-1 pt-0 mt-0'>
              <FaTelegramPlane />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CivilTopSection;
