import React, { useEffect, useState } from 'react';
import "./QuestionLayout.css"
import Questionsummary from '../Questionsummary/Questionsummary';
import Questionpreview from '../Questionpreview/Questionpreview';
import ResultPreview from '../../ResultPreview/ResultPreview';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { finalsubmittest } from '../../DynamicCourse/api';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { RiErrorWarningLine } from "react-icons/ri";
import swal from "sweetalert";
import { BASE_URL } from '../../../insightConfig/insightConfig';
import axios from 'axios';
import { FiClock } from 'react-icons/fi';
import { FcAlarmClock } from "react-icons/fc";
// import { toast } from 'react-toastify';
import { createBrowserHistory } from 'history';

function QuestionLayoutTestseries() {
  const alert = useAlert();
  const navigate = useNavigate();
  const { state: { testData } } = useLocation()
  console.log(testData)
  const allTestsReportsArray = JSON.parse(localStorage.getItem("TestSeriesReportArray"));
  //raaz work 
  const [testQuestions, setTestQuestions] = useState([])
  const [selectedQuestionNumber, setSelectedQuestionNumber] = useState(1)
  const [selectedQuestion, setSelectedQuestion] = useState()
  const [answerOfCurrentQuestion, setAnswerOfCurrentQuestion] = useState('')
  const [attemptedQuestions, setAttemptedQuestions] = useState([])
  const [markForReviewQuestionsArray, setMarkForReviewQuestionsArray] = useState([])
  const [toggleMarkForReview, setToggleMarkForReview] = useState(false)
  const [viewedQuestionsArray, setViewedQuestionsArray] = useState([])
  const [finishTest, setFinishTest] = useState(false)

  console.log(testQuestions)


  let { packageid } = useParams()
  const storedAuthToken = localStorage.getItem('authtoken');

  //time functionality
  let intialTime = Number(testData?.Duration) * 60
  const [timeRemaining, setTimeRemaining] = useState(intialTime);
  console.log(timeRemaining)
  const [timeUp, setTimeUp] = useState(false)
  const minutes = Math.floor(timeRemaining / 60);
  const seconds = (timeRemaining % 60);
  console.log('intialTime', intialTime)

  useEffect(() => {
    if (testQuestions && testQuestions.length > 0) {
      let intialTestResponseObj = new Array(testQuestions?.length).fill(null).map((_, index) => ({
        questionNumber: index + 1,
        selectedOption: '',
        correctOption: testQuestions.find(question => question.QNo == index + 1).Answer,
        markForReview: false,
        isQuestionViewed: false,
      }));

      const testSeriesReportArray = JSON.parse(localStorage.getItem("TestSeriesReportArray"));


      console.log('testSeriesReportArray', testSeriesReportArray)



      if (testSeriesReportArray && testSeriesReportArray?.length > 0) {
        const testToBeContinued = testSeriesReportArray.find((test) => test.packageId === packageid);

        console.log(testToBeContinued)
        if (testToBeContinued) {
          setTimeRemaining(testToBeContinued?.remainingTime)
          setSelectedQuestionNumber(testToBeContinued.lastQuestion);
          setAttemptedQuestions(testToBeContinued.userResponseObj?.filter((question) => question.selectedOption)?.map((question) => question.questionNumber));
        } else {        
          testSeriesReportArray.push({
            isTestFinished: false,
            packageId: packageid,
            reAttempt: testData.reAttempt,
            startTime: new Date(),
            lastQuestion: 1,
            remainingTime: intialTime,
            userResponseObj: intialTestResponseObj
          });

          localStorage.setItem("TestSeriesReportArray", JSON.stringify(testSeriesReportArray));
          setSelectedQuestionNumber(1);
        }
      } else {
        const newTestDetails = [{
          isTestFinished: false,
          packageId: packageid,
          reAttempt: testData.reAttempt,
          startTime: new Date(),
          lastQuestion: 1,
          remainingTime: testData.Duration * 60,
          userResponseObj: intialTestResponseObj,
        }];

        localStorage.setItem("TestSeriesReportArray", JSON.stringify(newTestDetails));
        setSelectedQuestionNumber(1);
        setAttemptedQuestions([]);
      }
    }
  }, [testQuestions])


  useEffect(() => {
    if (finishTest || timeUp) {
      return
    }
    const timer = setInterval(() => {
      setTimeRemaining(prevTime => {
        console.log('upper prevTime', prevTime)
        if (prevTime > 0) {
          const allTestsReportsArray = JSON.parse(localStorage.getItem("TestSeriesReportArray")) || [];
          const testIndex = allTestsReportsArray.findIndex(test => test.packageId === packageid);
          if (testIndex !== -1) {
            const copyOfTestDetails = [...allTestsReportsArray];
            copyOfTestDetails[testIndex].remainingTime = prevTime - 1;
            localStorage.setItem("TestSeriesReportArray", JSON.stringify(copyOfTestDetails));
          }
          return prevTime - 1;
        } else {
          setTimeUp(true)
          //  setFinishTest(true);
          swal(
            "Time's up! Please submit your test."
          )
          clearInterval(timer);
          return 0;
        }
      });


    }, 1000);
    return () => clearInterval(timer);
  }, [testData.Duration, packageid, timeRemaining]);


















  const getCurrentTime = () => {
    const currentTime = new Date();
    let hours = currentTime.getHours();
    let minutes = currentTime.getMinutes();
    let seconds = currentTime.getSeconds();
    hours = (hours < 10 ? '0' : '') + hours;
    minutes = (minutes < 10 ? '0' : '') + minutes;
    seconds = (seconds < 10 ? '0' : '') + seconds;
    return `${hours}:${minutes}:${seconds}`;
  }

  useEffect(() => {
    if (testQuestions?.length === 0) {
      axios.get(`${BASE_URL}/loadTestQuestions/${testData.testSeriesSn}/${packageid}/${storedAuthToken}`).then((response) => {
        setTestQuestions(response.data.questions)
      })
    }
    const allTestsReportsArray = JSON.parse(localStorage.getItem("TestSeriesReportArray"));
    const currentTest = allTestsReportsArray?.find((test) => test.packageId == packageid);
    console.log('currentTest', currentTest)
    if (currentTest?.isTestFinished == true) {
      setFinishTest(true)
      swal(
        "Test Finished! Please submit your test."
      )
    } else if (currentTest && currentTest?.remainingTime < 1) {
      setTimeUp(true)
      swal(
        "Time's up! Please submit your test."
      )
    }
  }, [])



  useEffect(() => {
    if (selectedQuestionNumber) {
      const allTestsReportsArray = JSON.parse(localStorage.getItem("TestSeriesReportArray"));
      console.log(allTestsReportsArray)
      if (allTestsReportsArray) {
        const currentTest = allTestsReportsArray?.find((test) => test.packageId == packageid);

        if (selectedQuestionNumber != testQuestions.length + 1) {
          currentTest.lastQuestion = selectedQuestionNumber;
        }

        const userResponseObjForCurrentTest = currentTest?.userResponseObj
        let questionObj = userResponseObjForCurrentTest?.find((question) => question.questionNumber == selectedQuestionNumber)
        console.log(questionObj)
        setAnswerOfCurrentQuestion(questionObj?.selectedOption)

        console.log(selectedQuestionNumber)
        console.log(questionObj)
        if (questionObj) {
          // questionObj.markForReview = false;
          questionObj.isQuestionViewed = true;
        }
        localStorage.setItem("TestSeriesReportArray", JSON.stringify(allTestsReportsArray));

        setAttemptedQuestions(userResponseObjForCurrentTest?.filter((question) => question.selectedOption)?.map((question) => question.questionNumber));

        setMarkForReviewQuestionsArray(userResponseObjForCurrentTest?.filter((question) => question.markForReview == true)?.map((question) => question.questionNumber))
        setViewedQuestionsArray(userResponseObjForCurrentTest?.filter((question) => question.isQuestionViewed == true)?.map((question) => question.questionNumber))


      }
    }
    let selectedQuestion = testQuestions.find(question => question.QNo == selectedQuestionNumber)
    setSelectedQuestion(selectedQuestion)
  }, [selectedQuestionNumber, testQuestions, answerOfCurrentQuestion, toggleMarkForReview])












  const renderhtml = (paragraphs) => {
    return <p className='renderhtml' dangerouslySetInnerHTML={{ __html: paragraphs }} />;
  };

  const renderhtml1 = (paragraphs) => {
    return <b className='renderhtml1' dangerouslySetInnerHTML={{ __html: paragraphs }} />;
  };

  const selectAnswerHandler = (value) => {
    setAnswerOfCurrentQuestion(value)
    const allTestsReportsArray = JSON.parse(localStorage.getItem("TestSeriesReportArray"));

    const currentTest = allTestsReportsArray?.find((test) => test.packageId == packageid);

    const selectedQuestionObj = currentTest?.userResponseObj?.find(questionObj => questionObj.questionNumber === selectedQuestionNumber);
    selectedQuestionObj.selectedOption = value;
    localStorage.setItem("TestSeriesReportArray", JSON.stringify(allTestsReportsArray));
  }
  const handleClear = () => {
    const allTestsReportsArray = JSON.parse(localStorage.getItem("TestSeriesReportArray"));
    const currentTest = allTestsReportsArray?.find((test) => test.packageId == packageid);
    console.log(currentTest)
    const selectedQuestionObj = currentTest?.userResponseObj?.find(questionObj => questionObj.questionNumber === selectedQuestionNumber);
    selectedQuestionObj.selectedOption = '';
    localStorage.setItem("TestSeriesReportArray", JSON.stringify(allTestsReportsArray));
    setAnswerOfCurrentQuestion('')
  }
  const handleMarkForReview = () => {
    const allTestsReportsArray = JSON.parse(localStorage.getItem("TestSeriesReportArray"));
    const currentTest = allTestsReportsArray?.find((test) => test.packageId == packageid);
    const selectedQuestionObj = currentTest?.userResponseObj?.find(questionObj => questionObj.questionNumber === selectedQuestionNumber);
    console.log(selectedQuestionObj)
    selectedQuestionObj.markForReview = true;
    localStorage.setItem("TestSeriesReportArray", JSON.stringify(allTestsReportsArray));
    setToggleMarkForReview(!toggleMarkForReview)
  }



  const handleFinishTest = () => {
    swal({
      title: "Are you sure?",
      text: "Once finished, you will not be able to return to the test",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((yes) => {
      if (yes) {
        const allTestsReportsArray = JSON.parse(localStorage.getItem("TestSeriesReportArray"));
        const currentTest = allTestsReportsArray?.find((test) => test.packageId == packageid);
        currentTest.isTestFinished = true;
        localStorage.setItem("TestSeriesReportArray", JSON.stringify(allTestsReportsArray));
        setFinishTest(true)
        swal(
          "Test Finished! Please submit your test."
        )
      }
    })
  }
  const deleteReportFromLS = () => {
    const indexOfCurrentTest = allTestsReportsArray?.findIndex((test) => test.packageId == packageid);
    allTestsReportsArray.splice(indexOfCurrentTest, 1)
    localStorage.setItem("TestSeriesReportArray", JSON.stringify(allTestsReportsArray));

  }

  const increaseAttemptCount = async () => {
    const response = await axios.post(BASE_URL + `/increaseAttemptCount/${testData.testSeriesSn}/${packageid}/${storedAuthToken}`)
  }


  const finalSubmitTest = async () => {

    try {

      const currentTest = allTestsReportsArray?.find((test) => test.packageId == packageid);
      let correctQuestions = 0;
      let wrongQuestions = 0;
      let correctMarks = 0;
      let wrongMarks = 0;
      let totalQuestions = testQuestions.length;
      let startTime = currentTest.startTime;
      let attemptedQuestions = 0;
      let notVisitedQuestions = 0;

      let userResponseObj = currentTest.userResponseObj;
      notVisitedQuestions = userResponseObj.filter((question) => question.isQuestionViewed !== true)?.length

      userResponseObj.map(obj => {
        if (obj.selectedOption != '') {
          attemptedQuestions++;
          if (obj.correctOption == obj.selectedOption) {
            correctMarks = correctMarks + Number(testData.MarksObtain)
            correctQuestions++;
          } else {
            wrongMarks = wrongMarks + Number(testData.MarkingMinus)
            wrongQuestions++;
          }

        }
      })
      let testReport = { totalQuestions, attemptedQuestions, correctQuestions, wrongQuestions, notVisitedQuestions, correctMarks, wrongMarks, negativeMarking: testData.MarkingMinus, marksPerQuestion: testData.MarksObtain, startTime, answerSheet: userResponseObj }
      if (currentTest?.reAttempt === false) {
        const response = await axios.post(BASE_URL + `/insertTestReport/${testData.testSeriesSn}/${packageid}/${storedAuthToken}`, testReport)
        if (response.data.statusCode == 200) {
          deleteReportFromLS();
          swal(response.data.message, {
            icon: "success",
            buttons: {
              confirm: {
                text: "OK",
                value: true,
                visible: true,
                closeModal: true
              }
            }
          }).then(() => {
            navigate('/testseries/finalresult', { state: { testData } })
          })
        }
      } else {
        testReport = {
          totalquestion: testReport.totalQuestions,
          attempted: testReport.attemptedQuestions,
          correct: testReport.correctQuestions,
          wrongquestions: testReport.wrongQuestions,
          testcorrectmarks: testReport.correctMarks,
          testwrongmarks: testReport.wrongMarks,
          answersheet: JSON.stringify(testReport.answerSheet)
        }
        deleteReportFromLS();
        increaseAttemptCount();
        navigate('/TestResultDetailView', { state: { testReport, testData } });
      }
    } catch (error) {
      swal("Oops", "Something went wrong! Please Try Again", { icon: "error" })
      console.log(error)
    }
  }

  const [unsavedChanges, setUnsavedChanges] = useState(true);
  const history = createBrowserHistory();
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (unsavedChanges) {
        const message = "Leave site? Changes you made may not be saved.";
        event.returnValue = message;  // Standard for most browsers
        return message;  // For some older browsers
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Block navigation using history
    const unblock = history.block((location, action) => {
      if (unsavedChanges) {
        const message = "Leave site? Changes you made may not be saved.";
        if (window.confirm(message)) {
          unblock();  // Allow the navigation
        } else {
          return false;  // Block the navigation
        }
      }
      return undefined;  // Allow the navigation
    });


    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblock();
    };
  }, [unsavedChanges]);





  return (
    <>
      <div className='container-fluid p-3' style={{ position: "absolute", top: "0", background: "white", zIndex: "100" }}>
        <div className='row' style={{ height: "100vh" }}>
          <div className='col-lg-8'>
            {!finishTest && !timeUp ? <>
              <h5 className='bg-warning p-3 d-inline-flex rounded-pill m-2'>
                <FcAlarmClock className='me-2' /> Time Remaining: <b className='text-danger mx-2'>{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}</b>
              </h5>
              <div className='card questionpreviewpadding'>
                <p><b>{selectedQuestion?.QNo}. {renderhtml(selectedQuestion?.Question)}</b></p>
                <ul className='answer-list'>
                  <li className={answerOfCurrentQuestion?.includes('A') ? 'selected' : ''} onClick={() => selectAnswerHandler('A')}>
                    <input
                      type="radio"
                      className='me-2 mb-1'
                      style={{ width: "20px", height: "20px" }}
                      name='option1'
                      id='option_1'
                      onClick={() => selectAnswerHandler('A')}
                      checked={answerOfCurrentQuestion?.includes('A')}
                    />
                    <label htmlFor='option_1'>
                      <b>(A) {renderhtml1(selectedQuestion?.OptionA)}</b>
                    </label>
                  </li>
                  <li className={answerOfCurrentQuestion?.includes('B') ? 'selected' : ''} onClick={() => selectAnswerHandler('B')}>
                    <input
                      type="radio"
                      className='me-2 mb-1'
                      style={{ width: "20px", height: "20px" }}
                      name='option2'
                      id='option_2'
                      onClick={() => selectAnswerHandler('B')}
                      checked={answerOfCurrentQuestion?.includes('B')}
                    />
                    <label htmlFor='option_2'>
                      <b>(B) {renderhtml1(selectedQuestion?.OptionB)}</b>
                    </label>
                  </li>
                  <li className={answerOfCurrentQuestion?.includes('C') ? 'selected' : ''} onClick={() => selectAnswerHandler('C')}>
                    <input
                      type="radio"
                      className='me-2 mb-1'
                      style={{ width: "20px", height: "20px" }}
                      name='option3'
                      id='option_3'
                      onClick={() => selectAnswerHandler('C')}
                      checked={answerOfCurrentQuestion?.includes('C')}
                    />
                    <label htmlFor='option_3'>
                      <b>(C) {renderhtml1(selectedQuestion?.OptionC)}</b>
                    </label>
                  </li>
                  <li className={answerOfCurrentQuestion?.includes('D') ? 'selected' : ''} onClick={() => selectAnswerHandler('D')}>
                    <input
                      type="radio"
                      className='me-2 mb-1'
                      style={{ width: "20px", height: "20px" }}
                      name='option4'
                      id='option_4'
                      onClick={() => selectAnswerHandler('D')}
                      checked={answerOfCurrentQuestion?.includes('D')}
                    />
                    <label htmlFor='option_4'>
                      <b>(D) {renderhtml1(selectedQuestion?.OptionD)}</b>
                    </label>
                  </li>

                </ul>
              </div>

              <div className='p-3 d-flex justify-content-betweenn flex-wrap'>
                <div className='col-lg-8 py-1 pe-3 col-md-8 col-sm-8 d-flex align-items-start flex-nowrap'>
                  {selectedQuestionNumber > 1 && <button type="button" className="btn bg-Question-white btn-md me-3" onClick={() => setSelectedQuestionNumber(selectedQuestionNumber - 1)}>Previous</button>}
                  <button type="button" className="btn bg-Question-white btn-md" onClick={handleClear}>clear</button>
                  <button type="button" className="btn bg-Question-white btn-md ms-3" style={{ whiteSpace: "nowrap" }} onClick={handleMarkForReview}>Mark for Review</button>

                </div>
                <div className='col-lg-4 py-1 col-md-2 col-sm-4 d-flex align-items-start justify-content-end'>

                  {selectedQuestionNumber < testQuestions.length && <button type="button" className='btn bg-Question-white btn-md' /* className={selectedQuestionNumber <= testQuestions.length ? "btn disable bg-Question-white btn-md" : "btn bg-Question-white btn-md"} */ onClick={() => setSelectedQuestionNumber(selectedQuestionNumber + 1)}>Save & Next</button>}
                </div>
              </div>
            </> :
              <>
                <ResultPreview testQuestions={testQuestions} attemptedQuestions={attemptedQuestions} viewedQuestionsArray={viewedQuestionsArray} />
                <div className='p-2 d-flex justify-content-around'>
                  <button type="button" className="btn bg-Question-white btn-md" onClick={finalSubmitTest}>Final Submit</button>
                </div>
              </>

            }

          </div>
          <div className='col-lg-4 p-3 card'>
            <Questionsummary attemptedQuestions={attemptedQuestions} markForReviewQuestionsArray={markForReviewQuestionsArray} testQuestions={testQuestions} viewedQuestionsArray={viewedQuestionsArray} setSelectedQuestionNumber={setSelectedQuestionNumber} selectedQuestionNumber={selectedQuestionNumber} handleFinishTest={handleFinishTest} finishTest={finishTest} />
          </div>
        </div >
      </div >
      {/* } */}
      {/* </FullScreen> */}
    </>
  )
}

export default QuestionLayoutTestseries