import React from 'react';
import './Achievements.css';
import { HiOutlineClipboardDocument } from "react-icons/hi2";
import { FaUserCheck } from "react-icons/fa";
import { PiGraduationCapFill } from "react-icons/pi";
import { FaUserFriends } from "react-icons/fa";
import Counter from '../CoutnerUp/Counter';

function Achievements() {
    return (
        <>
            {/* Achievement Section Start */}
            <div className='AchievementsContainer mb-3'>
                <div className='AchievementBox text-center'>
                    {/* Achievement Heading Start */}
                    <div className='Achieve_left py-1 pt-0'>
                        <h1 className='fw-bolder Achieve_heading'>Our Achievements</h1>
                        <p className='Achieve_para'>Don't just believe us; our outcomes speak for themselves</p>
                    </div>
                    {/* Achievement Heading End */}
                    {/* Achievement Card Start */}
                    <div className='Achieve_right py-3'>
                        <div className='Container-fluid'>
                            <div className='Achieve_wrap'>
                                <div className="card cards">
                                    <div className="translate-middle p-3 Achieve-tag tag-background1">
                                        <HiOutlineClipboardDocument />
                                    </div>
                                    <div className='card_img'>
                                        <h1 className='text-white Achieve_Head fw-bold pt-5 ps-3'>
                                            {/* Counter Up Section Start */}
                                            <Counter start={0} end={25} duration={2} />
                                            {/* Counter Up Section End */}
                                        </h1>
                                    </div>
                                    <div className="AchieveCard text-white">
                                        <div>No. Of <br />Courses Offered</div>
                                    </div>
                                </div>
                                <div className="card cards">
                                    <div className="translate-middle p-3 Achieve-tag tag-background2">
                                        <FaUserCheck />
                                    </div>
                                    <div className='card_img'>
                                        <h1 className='text-white Achieve_Head fw-bold pt-5 ps-3'>
                                            {/* Counter Up Section Start */}
                                            <Counter start={0} end={125} duration={2} />
                                            {/* Counter Up Section End */}
                                        </h1>
                                    </div>
                                    <div className="AchieveCard text-white">
                                        <div>No. Of<br />Faculties</div>
                                    </div>
                                </div>

                                <div className="card cards">
                                    <div className="translate-middle p-3 Achieve-tag tag-background1">
                                        <PiGraduationCapFill />
                                    </div>
                                    <div className='card_img'>
                                        <h1 className='text-white Achieve_Head fw-bold pt-5 ps-3'>
                                            {/* Counter Up Section Start */}
                                            <Counter start={0} end={75000} duration={2} />
                                            {/* Counter Up Section End */}
                                        </h1>
                                    </div>
                                    <div className="AchieveCard text-white">
                                        <div>No. Of<br />Students Selected</div>
                                    </div>
                                </div>
                                <div className="card cards">
                                    <div className="translate-middle p-3 Achieve-tag tag-background2">
                                        <FaUserFriends />
                                    </div>
                                    <div className='card_img'>
                                        <h1 className='text-white Achieve_Head fw-bold pt-5 ps-3'>
                                            {/* Counter Up Section Start */}
                                            <Counter start={0} end={710000} duration={2} />
                                            {/* Counter Up Section End */}
                                        </h1>
                                    </div>
                                    <div className="card-body AchieveCard text-white">
                                        <div>No. Of<br />Students Trained</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Achievement Card End */}
                </div>
            </div>
            {/* Achievement Section End */}
        </>
    )
}

export default Achievements;