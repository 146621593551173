import React, { useEffect, useState } from 'react';
import "../ReusableComponent/CIVIL/CivilBatchSection/CivilBatch.css";
import CivilBatch from '../ReusableComponent/CIVIL/CivilBatchSection/CivilBatch';
import CivilButton from '../ReusableComponent/CIVIL/CivilButton/CivilButton';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useParams } from 'react-router-dom';
import Radium from "radium";
import Loader from '../Loader/Loader';
import "../../jsx/ReusableComponent/CIVIL/CivilBatchSection/CivilBatch.css";
import 'animate.css';
import 'bootstrap/dist/css/bootstrap.min.css';


// import { BASE_URL } from '../../insightConfig/insightConfig';
// import CourseShareComponent from '../CourseShareComponent/CourseShareComponent';
import axios from 'axios';
import { BASE_URL } from '../../insightConfig/insightConfig';
import { BsCalendar2Week, BsCardChecklist, BsPersonWorkspace } from 'react-icons/bs';
import { FaFacebook, FaTelegram, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import { MdOutlineFileDownload } from 'react-icons/md';
import { IoLogoAndroid } from 'react-icons/io';
import { getBookData } from './helper';


function BookDetails() {
    const { bookTitleURL } = useParams();
    const [bookData, setCourseData] = useState({});
    const [reloadCourseData, setReloadCourseData] = useState(false);
    const [newsData, setNewsData] = useState([]);
    const location = useLocation();

    const { admissionStartDate, appDiscount, courseId, currentBatchID, demoVideoLink, description, discount, duration, examType, examTypeId, faculty, facultyId, imageURL, metaDescription, metaTitle, pdfURL, price, sNo, seatBookingPrice, startDate, subscriptionCount, syllabusPDFURL, tags, title, topicCount, topics, type, typeId, urlTitle } = bookData;


    const formatDate = (dateString) => {
        // Create a Date object from the dateString
        const date = new Date(dateString);
        // Get the day, month, and year
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        // Define the month names array
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        // Format the date string
        const formattedDate = `${day} ${monthNames[monthIndex]}, ${year}`;
        return formattedDate;
    };
    const storedAuthToken = localStorage.getItem('authtoken');
    useEffect(() => {
        const fetchData = async () => {
            const bookData = await getBookData(bookTitleURL)
            setCourseData(bookData)
            const newRepsonse = await axios.get(BASE_URL + `/current-affairs/News`)
            setNewsData(newRepsonse.data.data)
        }
        fetchData();
    }, [bookTitleURL, reloadCourseData]);
    console.log(bookData)
    if (!bookData) {
        return <div style={{ backgroundColor: "white", zIndex: "180", width: "100%", height: "100vh", position: "fixed", top: "0" }}><Loader /></div>
    }
    const componentName = "book";
    const p = bookData?.Price || 0;
    const displayPrice = Math.round(p * (100 - bookData?.Discount) * 0.01);
    const formattedDate = formatDate(bookData?.Bdate);


    const data = {
        courseTitleToShow: `${bookData.CourseTitle} : ${bookData.BatchName}`,
        date: formattedDate,
        seat: bookData.Subscriber,
        lesson: bookData.TopicCount,
        displayPrice: displayPrice,
        actualPrice: bookData.Price,
        discount: bookData.Discount,
        topic: bookData.Topic,
        Image: bookData.Image,
        Syllabuspdf: bookData.SyllabusPdf,
        VideoId: bookData.VideoId
    };


    const paragraphs = bookData.Description || [];

    const courseStyle = {
        before: {
            content: '""',
            width: "100%",
            backgroundImage: `url(${imageURL})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "top",
            opacity: "0.5",
            position: "absolute",
            filter: "blur(25px)",
            zIndex: "1",
        }
    }
    return (
        <>
            <Helmet>
                <title>{bookData.MetaTitle}</title>
                <meta name="description" content={`${bookData.MetaDescription}`}></meta>
                <meta property="og:title" content={bookData.MetaTitle} />
                <meta property="og:image" content={bookData.Image} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:description" content={bookData.MetaDescription} />
            </Helmet>

            {/* Civil GS Foundation Section Start */}
            <section className={`${bookTitleURL}-background dynamic-course`}>
                <section style={courseStyle.before} className='dynamic-course-before' />
                {bookData && <div className='CivilBatch-row laptop-view'>
                    <div className='col-lg-8 col-md-6 col-sm-12'>
                        <div className='row'>
                            <h1 className='fs-3 text-white d-flex justify-content-between'>{title}</h1>
                            <hr className='text-white' />
                        </div>
                        <div className='row'>
                            <div className='col-lg-4 col-md-4 col-sm-4 text-center p-3'>
                                <BsCalendar2Week className='text-white icon-size mb-2' />
                                <h5 className='text-white fs-6'>PRACTICE QUIZ</h5>
                            </div>
                            <div className='col-lg-4 col-md-4 col-sm-4 text-center p-3'>
                                <BsPersonWorkspace className='text-white icon-size mb-2' />
                                <h5 className='text-white fs-6'>Previous Year Qs.</h5>
                            </div>
                            <div className='col-lg-4 col-md-4 col-sm-4 text-center p-3'>
                                <BsCardChecklist className='text-white icon-size mb-2' />
                                <h5 className='text-white fs-6'>{topicCount} LESSONS</h5>
                            </div>
                        </div>
                        {/* <div className='row'>
                            <div className="d-flex gap-3">
                                <button disabled={!syllabusPDFURL} className="btn bg-orange w-50 fw-bold fs-6 p-2" type="button" onClick={() => window.open(syllabusPDFURL, '_blank')}><MdOutlineFileDownload className='fw-bold mb-1 fs-5' />Download Syllabus</button>
                                <Link className="btn bg-purple w-50 fw-bold fs-6 p-2" to="#" onClick={openModal} type="button"><IoLogoAndroid className='fw-bold mb-1 fs-5' />Download App</Link>


                            </div>
                        </div> */}
                        <div className='row py-0'>
                            <p className='text-white renderhtml' dangerouslySetInnerHTML={{ __html: description }} />
                            <ul className='d-flex justify-content-bewteen'>
                                <li className='list-group-item m-1 my-0'>
                                    <Link className='list-group-item bg-primary p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                        <FaFacebook />
                                    </Link>
                                </li>
                                <li className='list-group-item m-1 my-0'>
                                    <Link className='list-group-item bg-success p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                        <FaWhatsapp />
                                    </Link>
                                </li>
                                <li className='list-group-item m-1 my-0'>
                                    <Link className='list-group-item bg-danger p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                        <FaYoutube />
                                    </Link>
                                </li>
                                <li className='list-group-item m-1 my-0'>
                                    <Link className='list-group-item bg-primary p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                        <FaTelegram />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className='row'>

                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12'>
                        <div className='d-grid pb-2'>
                            <img src={imageURL} className="batch-img" alt='...' />
                        </div>

                    </div>
                </div>}
            </section>
            <CivilButton bookData={bookData} newsData={newsData} componentName={componentName} />
            {/* Civil GS Foundation Section End */}
        </>
    )
}

export default Radium(BookDetails); 
