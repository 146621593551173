import React, { useEffect, useState } from 'react';
import CivilButton from '../ReusableComponent/CIVIL/CivilButton/CivilButton';
import Dashboardleft2 from '../DashboardLeft/DashboardLeft2';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Loader from '../Loader/Loader';
import axios from 'axios';
import { BASE_URL } from '../../insightConfig/insightConfig';
import "../Dashborad/dashboard.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import { FaNoteSticky } from 'react-icons/fa6';
import { FaUserFriends } from 'react-icons/fa';
import { getBookData } from './helper';


function BookTestSeries() {
    const { titleURL } = useParams();

    const [bookData, setBookData] = useState(null);
    const [reloadTestData, setReloadTestData] = useState(false);
    // const [testSeriesData, setTestSeriesData] = useState(null);
    const componentName = "bookTestSeries";


    // Update prevPath whenever the route changes
    // useEffect(() => {
    //   setPrevPath(location.pathname);
    //   localStorage.setItem('prevPath', location.pathname);
    // }, [location.pathname, setPrevPath]);
    const storedAuthToken = localStorage.getItem('authtoken');
    useEffect(() => {
        getBookData(titleURL).then(bookData => {
            setBookData(bookData)
        })
    }, [reloadTestData, titleURL]);

    console.log(bookData)

    if (!bookData) {
        return <div style={{ backgroundColor: "white", zIndex: "180", width: "100%", height: "100vh", position: "fixed", top: "0" }}><Loader /></div>;
    }

    const paragraphs = bookData?.Description;
    // const testtoggleData = testSeriesData;

    return (
        <>
            <Helmet>
                <title>{bookData?.metaTitle}</title>
                <meta name="description" content={`${bookData?.metaDescription}`}></meta>
            </Helmet>
            {/* Civil Test Page Start */}
            <div className='Dashboard-background'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-12 col-sm-12 dashboardmain-left p-3'>
                            <div className='dashboardleft-background'>
                                <div className='dash1'>
                                    <img src={bookData?.imageURL} alt={"..."} className='dash2-img' />
                                </div>
                            </div>

                        </div>
                        <div className='col-lg-9 col-md-12 col-sm-12'>
                            <CivilButton bookData={bookData} componentName={componentName} />
                        </div>
                    </div>
                </div>
            </div>
            {/* Civil Test Page End */}
        </>
    )
}

export default BookTestSeries;
