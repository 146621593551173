import React, { useState, useEffect, useRef } from 'react';
import { BsFillTelephoneForwardFill } from "react-icons/bs";
import "./Header.css";
import logo from "../../Image/logo.png";
import Civil from '../ReusableComponent/CIVIL/Civil';
import Neet from '../ReusableComponent/NEET/Neet';
import UG from '../ReusableComponent/UGNET/UG';
import { IoIosArrowDown } from "react-icons/io";
import { RiMenu4Fill } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import { FaCircleUser } from "react-icons/fa6";
import { FaHome } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import userImg from "../../Image/user.png";
import { RxDashboard } from "react-icons/rx";
import { MdLogout } from "react-icons/md";
import { useAlert } from 'react-alert';
import { HiMiniUsers } from "react-icons/hi2";
import { MdLocalPhone } from "react-icons/md";
import { HiMiniClipboard } from "react-icons/hi2";
import swal from "sweetalert";
import { useQuizGoingOn } from '../Context/QuizGoingOn';

const Header = ({ isLoggedIn, setIsLoggedIn, setAuthtoken, setispayment, ispayment }) => {
    const [icon, SetIcon] = useState(true);
    const [isSticky, setIsSticky] = useState(false);
    const alert = useAlert();
    const navigate = useNavigate();
    const [isQuizGoingOn, setIsQuizGoingOn] = useQuizGoingOn();
    const timeoutRef = useRef(null);

    console.log(isQuizGoingOn)


    // let quizGoingOn = localStorage.getItem("quizGoingOn");
    // console.log(quizGoingOn)

    const handleLogOut = () => {
        setIsLoggedIn(false)
        localStorage.removeItem('authtoken');
        setAuthtoken(null)
        alert.show('Loggout Successfully');
        setTimeout(() => {
            navigate('/');
        }, 100)

    };

    useEffect(() => {
        // Add a scroll event listener
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setIsSticky(scrollPosition > 0);
        };
        window.addEventListener('scroll', handleScroll);

        // Add a active class after clicking a list
        $(".navbar .nav-link").on("click", function () {
            $(".navbar").find(".active").removeClass("active");
            $(this).addClass("active");
        });

        // Handle hover events on .nav-item.dropdown
        $('.nav-item.dropdown').hover(
            function () {
                $(this).addClass('show');
                $(this).find('.dropdown-menu').addClass('show');
                $(this).find('.dropdown-menu').removeClass('fade-out');
            },
            function () {
                // Hide the div after a certain delay (for demonstration purposes)
                const hideDivTimeout = setTimeout(() => {
                    $(this).removeClass('show');
                    $(this).find('.dropdown-menu').removeClass('show');
                    $(this).find('.dropdown-menu').removeClass('fade-out');
                }, 100); // Adjust the delay as needed
                return () => {
                    clearTimeout(hideDivTimeout);
                };
            }
        );

        $('.navbar .nav-item .civilpadding').on('click', function () {
            $('.navbar .nav-item .dropdown-menu').removeClass('show').addClass('fade-out');
            handleispayment()
        });

        return () => {
            // Remove the scroll event listener on component unmount
            window.removeEventListener('scroll', handleScroll);
        };

    }, []); // Run this effect once when the component mounts

    function disappear() {
        SetIcon(!icon)
    }

    function handleispayment() {
        setispayment(false)
        localStorage.setItem('ispayment', JSON.stringify(false));
    }







    const handleNavigate = (event, path) => {
        event.preventDefault()
        if (isQuizGoingOn) {
            swal({
                title: "Are you sure?",
                text: "Do you really want to leave the test for now!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                closeOnClickOutside: false,
                buttons: ["No, stay Here", "Yes, leave the test"],
                confirmButtonText: "Yes, leave the test"
            }).then((yes) => {
                if (yes) {
                    navigate(path)
                } else {
                    console.log('stay here')
                }
            })
        } else {
            if (path == '/StudentDashboard') {
                return
            }else{
                navigate(path)
            }
            
        }
    }

    const [showCivil, setShowCivil] = useState(false)

    const [activeMouse, setActiveMouse] = useState(false);
    // console.clear()
    console.log(activeMouse)
    const handleMouseOver = (event) => {
        setActiveMouse(true);
        event.preventDefault()
        if (isQuizGoingOn) {
            timeoutRef.current = setTimeout(() => {
                swal({
                    title: "Are you sure?",
                    text: "Do you really want to leave the test for now!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                    closeOnClickOutside: false,
                    buttons: ["No, stay Here", "Yes, leave the test"],
                    confirmButtonText: "Yes, leave the test"
                }).then((yes) => {
                    if (yes) {
                        navigate('/home')
                    } else {
                        console.log('stay here')
                    }
                })


            }, 700)


        } else {
            // navigate(path)
        }
    }

    const handleMouseOut = (event) => {
        setActiveMouse(false);
        clearTimeout(timeoutRef.current); // Cancel the swal if the mouse leaves the element
    };

    return (
        <>
            {/* Header Section Start  */}
            <div className='header-width'>
                <div className='header-background'>
                    <div className='head_left'>
                        <Link to="/testseries/Pre-Cum-Mains-Test-Series" type="button" className="btn btn-warning fw-bold me-2 small-font btn-hover">PRE-CUM-MAINS TEST SERIES 2024</Link>
                        <Link to="/historycourse/history-optional-courses" type="button" className="btn btn-warning fw-bold small-font btn-hover">HISTORY OPTIONAL COURSES</Link>
                    </div>
                    <div className='head_right'>
                        <div className="fs-5 d-flex text-white align-items-center">
                            <Link to="#" className='list-group-item p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                <BsFillTelephoneForwardFill />
                            </Link>
                            011-45090051, 9818333201
                        </div>
                    </div>
                </div>
            </div>
            {/* Header Section End */}

            {/* Navbar Section Start */}
            <nav className={`navbar ${isSticky ? 'sticky-top' : ''} navbar-expand-lg navbar-light navbar-background`} >
                <div className="container-fluid mobile-nav">
                    <button className="navbar-toggler" onClick={() => disappear()} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        {/* {icon ? <RiMenu4Fill className='fs-1' /> : <RxCross2 className='fs-1' />} */}
                        <label className="hamburger">
                            <input type="checkbox" />
                            <svg viewBox="0 0 32 32">
                                <path className="line line-top-bottom" d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"></path>
                                <path className="line" d="M7 16 27 16"></path>
                            </svg>
                        </label>
                    </button>
                    <Link className="navbar-brand" to="/">
                        <img src={logo} className='image' alt='logo' />
                    </Link>
                    <Link className="navbar-toggler navbar-user" to="/login">
                        <FaCircleUser className='fs-1' />
                    </Link>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav navbar-list mobile-link ms-auto mb-2 mb-lg-0">
                            <li className="nav-item me-2">
                                <button className="nav-link  list-link fw-bold" id="home-tab" aria-current="page" /* to="/home" */ onClick={(event) => handleNavigate(event, '/home')}>HOME</button>
                            </li>
                            <li className="nav-item dropdown me-2" >
                                <button key={1} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} className="nav-link list-link dropdown-bs-toggle fw-bold" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" >
                                    CIVILS
                                    <IoIosArrowDown />
                                </button>
                                <ul className="dropdown-menu drop-background civil-background" aria-labelledby="navbarDropdown">
                                    {/* DropDown Component Start */}
                                    {!isQuizGoingOn && <Civil />}
                                    {/* DropDown Component End */}
                                </ul>
                            </li>
                            <li className="nav-item dropdown me-2" >
                                <button key={2} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} className="nav-link list-link dropdown-bs-toggle fw-bold" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    NEET
                                    <IoIosArrowDown />
                                </button>
                                <ul className="dropdown-menu drop-background neet-background" aria-labelledby="navbarDropdown">
                                    {/* DropDown Component Start */}
                                    {!isQuizGoingOn && <Neet />}
                                    {/* DropDown Component End */}
                                </ul>
                            </li>
                            <li className="nav-item me-2" >
                                <button className="nav-link list-link fw-bold" aria-current="page" onClick={(event) => handleNavigate(event, '/cuet')}>CUET</button>
                            </li>
                            <li className="nav-item dropdown me-2" >
                                <button key={3} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} className="nav-link list-link dropdown-bs-toggle fw-bold" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    UGC-NET
                                    <IoIosArrowDown />
                                </button>
                                <ul className="dropdown-menu drop-background ugc-neet-background" aria-labelledby="navbarDropdown">
                                    {/* DropDown Component Start */}
                                    {!isQuizGoingOn && <UG />}
                                    {/* DropDown Component End */}
                                </ul>

                            </li>
                            <li className="nav-item me-2">
                                <button className="nav-link list-link fw-bold" aria-current="page" to="/daily-current-affairs/News" onClick={(event) => handleNavigate(event, '/daily-current-affairs/News')}>CURRENT AFFAIRS</button>
                            </li>
                            <li className="nav-item me-2">
                                <button className="nav-link list-link fw-bold" aria-current="page" to="/blogs" onClick={(event) => handleNavigate(event, '/blogs')}>BLOGS</button>
                            </li>
                        </ul>
                        <form className="d-flex">
                            {!isLoggedIn ? <Link className="btn fw-bold login-background text-white" type="submit" to="/login">LOGIN</Link> :
                                <div className="dropdown">
                                    <button className="btn fw-bold d-flex profile-user-img dropdown-bs-toggle" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" type="submit" to="/profile">
                                        <img className='profile-user phone-user' src={userImg} onClick={(event) => handleNavigate(event, '/StudentDashboard')} />
                                    </button>
                                    <ul className="dropdown-menu adminmenu" aria-labelledby="dropdownMenuLink">
                                        <li><Link className="dropdown-item adminmenulink" to="/StudentDashboard"><RxDashboard className='me-3' />Dashboard</Link></li>
                                        <li><Link className="dropdown-item adminmenulink" onClick={handleLogOut} type='submit' to="#"><MdLogout className='me-3' />Logout</Link></li>
                                    </ul>
                                </div>}
                        </form>
                    </div>
                </div>
            </nav>
            {/* Footer navbar Section STart */}
            {/* Navbar Show in only phone Device */}
            <div className='Foot-navbar'>
                <div className='foot-nav-container'>
                    <Link to="/" className='list-group-item'>
                        <div className='fn1'>
                            <FaHome />
                            <b>Home</b>
                        </div>
                    </Link>
                    <Link to="/login" className='list-group-item'>
                        <div className='fn2'>
                            <HiMiniUsers />
                            <b>Login</b>
                        </div>
                    </Link>
                    <Link to="/upsc-quiz" className='list-group-item'>
                        <div className='fn3'>
                            <HiMiniClipboard />
                            <b>Quiz</b>
                        </div>
                    </Link>
                    <Link to="/contact-us" className='list-group-item'>
                        <div className='fn4'>
                            <MdLocalPhone />
                            <b>Contact</b>
                        </div>
                    </Link>
                </div>
            </div>
            {/* Footer navbar Section End */}
            {/* Navbar Section End */}
        </>
    )
}

export default Header;