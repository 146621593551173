import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// import CardsPage from '../../CardsPage/CardsPage';
// import TwoRowThreeColumnLayout from '../../../EffectonLoad/TwoThreecolumnLayout';
// import { fetchCourseCard as fetchCoursesByExamBody } from '../../DynamicCourse/api';
import axios from 'axios';
// import { BASE_URL1 } from '../../../insightConfig/insightConfig';
import { BASE_URL1 } from '../../insightConfig/insightConfig';
import { Link } from 'react-router-dom';
import aboutimg from "../../Image/aboutimg.png"
import { BsJournals } from 'react-icons/bs';
import { FaUserFriends } from 'react-icons/fa';
import TwoRowThreeColumnLayout from '../EffectonLoad/TwoThreecolumnLayout';

function Books() {
    const [books, setBooks] = useState([]);
    useEffect(() => {
        // Fetch data from API
        const fetchData = async () => {
            try {
                const response = await axios.get(BASE_URL1 + '/v2/getBooks');
                console.log(response.data.books)

                setBooks(response.data.books);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();

    }, []);
    if (books?.length === 0) {
        return <><TwoRowThreeColumnLayout /></>
        // return <>No Data Found</>
    }


    function isValidUrl(string) {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    }
    console.log(books)
    return (
        <>
            <Helmet>
                <title>Index</title>
            </Helmet>
            <div>
                <div className='AllCardBackground'>
                    <h1 className='AllCard_heading fw-bold fs-4'>Books</h1>
                    <div className='AllCard_Box' >
                        {books?.map((book, index) => (
                            <div className="card Allcard" key={index}>
                                <Link to={`/books/${book.titleHeading}`} className='text-decoration-none text-black'>
                                    <img src={isValidUrl(book.image) ? book.image : aboutimg} className="card-img-top" alt={book.courseTitle} style={{ height: "250px" }} />
                                </Link>
                                <div className="card-body All-bg">
                                    <Link to={`/books/${book.titleHeading}`} className='text-decoration-none text-black'>
                                        <h6 className="fw-bold fs-5">{book.courseTitle}</h6>
                                    </Link>
                                    <hr />
                                    <div className="d-flex justify-content-between align-items-center">
                                        <Link to="#" className="card-link list-group-item">
                                            <h6 className='d-flex align-items-center'><BsJournals className='me-1' />{book.topicCount || 0} LESSONS</h6>
                                            {/* <h6 className='d-flex align-items-center'><FaUserFriends className='me-1' /> {book.subscribers || 0} SUBSCRIBERS</h6> */}
                                        </Link>
                                        <Link to={`/books/${book.titleHeading}`} state={{ isEnrolled: book.isEnrolled }} className="btn-start list-group-item">Proceed</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Books;
