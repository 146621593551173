import React from 'react'
import gsImg from "../../Image/gsprism.png";
import ptImg from "../../Image/ptmaster.png";
import upscImg from "../../Image/upsc.png";
import { Link } from 'react-router-dom';
import "../ReusableComponent/CIVIL/CivilrightSection/CivilrightSection.css";

function ImageComponent() {
    return (
        <>
            <div className="card-body">
                <Link to="/testseries/pre-cum-mains-test-series-for-upsc" className='img-link'>
                    <img src={gsImg} className="img-civil-border" alt="..." />
                </Link>
            </div>
            <div className="card-body">
                <Link to="/testseries/prelims-test-series-for-ias-exam" className='img-link'>
                    <img src={ptImg} className="img-civil-border" alt="..." />
                </Link>
            </div>
            <div className="card-body" >
                <Link to="/testseries/History-Optional-Test-Series-for-upsc-2024" className='img-link'>
                    <img src={upscImg} className="img-civil-border" alt="..." />
                </Link>
            </div>
        </>
    )
}

export default ImageComponent;