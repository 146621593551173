import './App.css';
import Header from './jsx/Header/Header';
import Footer from './jsx/Footer/Footer';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from './jsx/Home/Home';
import Login from './jsx/Login/Login';
import Signup from './jsx/Signup/Signup';
import Forgot from './jsx/Forgot/Forgot';
import Civilabout from './jsx/CivilPage/CivilHome/Civilabout';
import { HelmetProvider } from 'react-helmet-async';
import Current from './jsx/CurrentPage/Current';
import BlogPage from './jsx/BlogPage/BlogPage';
import HistoryOptionalCourses from './jsx/CivilPage/CivilHistory/HistoryOptionalCourses';
import CivilsCard from './jsx/CivilPage/CivilsCard/CivilsCard';
import CivilsTest from './jsx/CivilPage/CivilsCard/CivilsTest';
import Contact from './jsx/Contact/Contact';
import Terms from './jsx/TermsCondition/Terms';
import DynamicCourse from './jsx/DynamicCourse/DynamicCourse';
import DynamicNews from './jsx/DynamicNews/DynamicNews';
import Dynamicblog from './jsx/Dynamicblog/Dynamicblog';
import { useEffect, useState } from 'react';
import Dashborad from './jsx/Dashborad/Dashborad';
import CourseDescription from './jsx/CourseDescription/CourseDescription';
import Cuet from './jsx/CUET/Cuet';
import DynamicToday from './jsx/Dynamicblog/DynamicToday';
import DynamicaboutPage from './jsx/DynamicaboutPage/DynamicaboutPage';
import DynamicTestseries from './jsx/DynamicTestseries/DynamicTestseries';
import { BASE_URL } from './insightConfig/insightConfig';
import TestStart from './jsx/TestStartComponent/TestStart';
import QuestionLayoutTestseries from './jsx/QuizComponent/QuestionLayout/QuestionLayoutTestseries';
import Finalresult from './jsx/Finalresult/Finalresult';
import Homequiz from './jsx/Homequiz/Homequiz';
import { v4 as uuidv4 } from 'uuid';
import DynamicTestseries1 from './jsx/DynamicTestseries/DynamicTestseries1';
import Reportcard from './jsx/Reportcard/Reportcard';
import Reportcard1 from './jsx/Reportcard/Reportcard1';
import PaymentSuccess from './jsx/PaymentSuccess/PaymentSuccesscourse';
import PaymentSuccesstest from './jsx/PaymentSuccess/PaymentSuccesstest';
import Reportcardreattempted from './jsx/Reportcard/Reportcardreattempted';
import QuestionLayoutTestseriesContinue from './jsx/QuizComponent/QuestionLayout/QuestionLayoutContinueTestseries';
import Books from './jsx/Books/Books';
import BookDetails from './jsx/Books/BookDetails';
import BookTestSeries from './jsx/Books/BookTestSeries';
import StartBookTest from './jsx/Books/StartBookTest';
import AttemptTest from './jsx/Books/AttemptTest';

function App() {
  useEffect(() => {
    const uniqueUserId = localStorage.getItem('uniqueUserId'); 
    console.log(uniqueUserId)
    if (!uniqueUserId) {
      const newId = uuidv4();      
      localStorage.setItem('uniqueUserId', newId?.replace(/-/g, ''));
    }
  }, [])

  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const storedLoggedIn = localStorage.getItem('isLoggedIn');
    return storedLoggedIn ? JSON.parse(storedLoggedIn) : false;
  });



  const [testSn, settestSn] = useState(() => {
    const storedAuthToken = localStorage.getItem('testSn');
    return storedAuthToken ? storedAuthToken : null;
  })

  const [QuizSubjectid, setQuizSubjectid] = useState(() => {
    const storedAuthToken = localStorage.getItem('QuizSubjectid');
    return storedAuthToken ? storedAuthToken : 0;
  })

  const [QuizSectionid, setQuizSectionid] = useState(() => {
    const storedAuthToken = localStorage.getItem('QuizSubjectid');
    return storedAuthToken ? storedAuthToken : 0;
  })

  const [QuizQuestionurl, setQuizQuestionurl] = useState(() => {
    const storedAuthToken = localStorage.getItem('QuizQuestionurl');
    return storedAuthToken ? storedAuthToken : "Quizurl";
  })

  useEffect(() => {
    const storedQuestionArrayString = localStorage.getItem('QuizquestionArray');
    if (storedQuestionArrayString) {
      const arraystring = JSON.parse(storedQuestionArrayString);
      setQuizQuestionurl(arraystring[1].titleURL)
    }
  }, [])

  const [iscorrect, setiscorrect] = useState(null);
  const [PositiveScore, setPositiveScore] = useState(() => {
    const pscore = localStorage.getItem(`${testSn}positiveScore`);
    return pscore ? pscore : 0;
  })

  const [NegativeScore, setNegativeScore] = useState(() => {
    const nscore = localStorage.getItem(`${testSn}negativeScore`);
    return nscore ? nscore : 0;
  })

  const [correctdataarray, setcorrectdataarray] = useState(() => {
    const correctarray = localStorage.getItem(`${testSn}correctAnswers`);
    return correctarray ? JSON.parse(correctarray) : []
  })
  const [incorrectdataarray, setincorrectdataarray] = useState(() => {
    const incorrectarray = localStorage.getItem(`${testSn}incorrectAnswers`);
    return incorrectarray ? JSON.parse(incorrectarray) : []
  })

  const [coursesectionId, setcoursesectionId] = useState(() => {
    const storedAuthToken = localStorage.getItem('coursesectionId');
    return storedAuthToken ? storedAuthToken : '';
  })

  const [authtoken, setAuthtoken] = useState(() => {
    const storedAuthToken = localStorage.getItem('authtoken');
    return storedAuthToken ? storedAuthToken : null;
  });





  const [prevPath, setPrevPath] = useState(() => {
    const storedAuthToken = localStorage.getItem('prevPath');
    return storedAuthToken ? storedAuthToken : null;
  });

  const [rule, setRule] = useState(() => {
    const storedAuthToken = localStorage.getItem('rule');
    return storedAuthToken ? storedAuthToken : '';
  });

  const [rulesubjective, setRulesubjective] = useState(() => {
    const storedAuthToken = localStorage.getItem('rulesubjective');
    return storedAuthToken ? storedAuthToken : '';
  });
  const [testcourseid, settestcourseid] = useState(() => {
    const storedAuthToken = localStorage.getItem('testcourseid');
    return storedAuthToken ? storedAuthToken : null;
  });
  // const [QuestionPDF, setQuestionPDF] = useState(() => {
  //   const storedAuthToken = localStorage.getItem('QuestionPDF');
  //   return storedAuthToken ? storedAuthToken : null;
  // });
  // const [AnswerBookletPDF, setAnswerBookletPDF] = useState(() => {
  //   const storedAuthToken = localStorage.getItem('AnswerBookletPDF');
  //   return storedAuthToken ? storedAuthToken : null;
  // });
  // const [ModelAnswerPDF, setModelAnswerPDF] = useState(() => {
  //   const storedAuthToken = localStorage.getItem('ModelAnswerPDF');
  //   return storedAuthToken ? storedAuthToken : null;
  // });
  const [testpackageid, settestpackageid] = useState(() => {
    const testpackageid = localStorage.getItem('testpackageid');
    return testpackageid ? testpackageid : null;
  });

  const [sessionid, setsessionid] = useState(() => {
    const testsession = localStorage.getItem(`testsession${testSn}`);
    // return userdata
    // return userdata ? JSON.parse(userdata) : ''
    return testsession
  });

  const [testtype, settesttype] = useState(() => {
    const testtype = localStorage.getItem('testtype');
    return testtype ? JSON.parse(testtype) : null;
  })
  const [testDuration, settestDuration] = useState(() => {
    const testDuration = localStorage.getItem('testDuration');
    return testDuration ? testDuration : null;
  })
  const [testmarks, settestmarks] = useState(() => {
    const testmarks = localStorage.getItem('testmarks');
    return testmarks ? testmarks : null;
  })
  const [testQuestion, settestQuestion] = useState(() => {
    const testQuestion = localStorage.getItem('testQuestion');
    return testQuestion ? testQuestion : null;
  })
  const [testAccesscode, settestAccesscode] = useState(() => {
    const testAccesscode = localStorage.getItem('testAccesscode');
    return testAccesscode ? testAccesscode : null;
  })

  const [testTitle, settestTitle] = useState(() => {
    const testTitle = localStorage.getItem('testTitle');
    return testTitle ? JSON.parse(testTitle) : null;
  })





  const [coursetopicsn, setCoursetopicsn] = useState(() => {
    const storedAuthToken = localStorage.getItem('coursetopicsn');
    return storedAuthToken ? storedAuthToken : '';
  })





  const [paymentdetailtest, setpaymentdetailtest] = useState(() => {
    const storedAuthToken = localStorage.getItem('paymentobjcourse');
    return storedAuthToken ? JSON.parse(storedAuthToken) : null;
  })



  // const [coursestatus, setcoursestatus] = useState(()=>{
  //   const storedAuthToken = localStorage.getItem('coursestatus');
  //   return storedAuthToken ? JSON.parse(storedAuthToken) : '';
  // })

  const [teststatus, setteststatus] = useState(() => {
    const storedAuthToken = localStorage.getItem('teststatus');
    return storedAuthToken ? JSON.parse(storedAuthToken) : '';
  })






  const [coursepayment, setcoursepayment] = useState(0);
  const [coursenotpayment, setcoursenotpayment] = useState(0);

  const [ispayment, setispayment] = useState(() => {
    const ispayment = localStorage.getItem('ispayment');
    return ispayment ? JSON.parse(ispayment) : '';
  })



  const [TestTitle1, setTestTitle1] = useState(() => {
    const CourseTitle1 = localStorage.getItem('TestTitle1');
    return CourseTitle1 ? CourseTitle1 : '';
  })

  // const [ispayment, setispayment] = useState()

  useEffect(() => {
    localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));
  }, [isLoggedIn]);

  const [dateState, setDateState] = useState('')
  const [datefilter, setDateFilter] = useState('')
  const [datefilteredData, setDateFilteredData] = useState('')

  // const handleDateinput = async (date) => {
  //   // setSelectedDate(date)
  //    (date)
  // };

  const handleDateinput = async (date) => {
    // setSelectedDate(date)


    const originalDate = new Date(date);
    const formattedDate = originalDate.toISOString().split('T')[0]; // Extracting only the date part

    try {
      setDateFilter(true);
      const response = await fetch(`${BASE_URL}/current-affairs-date-filter/${dateState}/${formattedDate}`);
      const data = await response.json();
      setDateFilteredData(data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  return (
    <HelmetProvider>
      <div className="App">
        <BrowserRouter>
          <Header setispayment={setispayment} ispayment={ispayment} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}  setAuthtoken={setAuthtoken} />
          <Routes>
            {/* <Route path='*' element={!isLoggedIn && <Navigate to="/" />} /> */}
            <Route path='/' element={<Home setPrevPath={setPrevPath} QuizQuestionurl={QuizQuestionurl} QuizSectionid={QuizSectionid} QuizSubjectid={QuizSubjectid} />} />
            <Route path='/home' element={<Home setPrevPath={setPrevPath} QuizQuestionurl={QuizQuestionurl} QuizSectionid={QuizSectionid} QuizSubjectid={QuizSubjectid} />} />
            <Route path='/historycourse/history-optional-courses' element={<HistoryOptionalCourses />} />
            <Route path='/civils' element={<CivilsCard setispayment={setispayment} />} />
            <Route path='/civilstestseries' element={<CivilsTest />} />
            <Route path='/about/:abouturl' element={<DynamicaboutPage />} />
            <Route path='/about-us' element={<Civilabout />} />
            <Route path="/course/:coursename" element={<DynamicCourse />} />
            <Route path="/course-lesson/:lessonurl" element={<CourseDescription />} />
            <Route path='/testseries/:testseriesname' element={<DynamicTestseries />} />
              
              {/* books */}
            <Route path='/books' element={<Books />} />
            <Route path='/books/:bookTitleURL' element={<BookDetails />} />
            <Route path='/books/test-series/:titleURL' element={<BookTestSeries />} />
            <Route path='/books/test-series/start-test/:courseId/:packageId' element={<StartBookTest />} />
            <Route path='/books/test-series/attemt/:courseId/:packageId' element={<AttemptTest />} />

            <Route path="/daily-current-affairs/News" element={<Current />} />
            <Route path="/readmore/News/:newsurl" element={<DynamicNews handleDateinput={handleDateinput} />} />
            <Route path='/login/'>
              <Route path='' element={isLoggedIn ? <Navigate to="/StudentDashboard" /> : <Login setIsLoggedIn={setIsLoggedIn} setispayment={setispayment} setAuthtoken={setAuthtoken} prevPath={prevPath} />} />
              <Route path='forgot' element={<Forgot />} />
            </Route>
            <Route path='/blogs' element={<BlogPage />} />
            <Route path='/upsc-quiz/' element={<Homequiz />} />
            <Route path='/cuet' element={<Cuet />} />
            <Route path='/cuet/:cuetname' element={<DynamicTestseries1 ispayment={ispayment} coursepayment={coursepayment} coursenotpayment={coursenotpayment} isAuth={isLoggedIn} authtoken={authtoken} setPrevPath={setPrevPath} setRule={setRule} setRulesubjective={setRulesubjective} settestcourseid={settestcourseid} settestpackageid={settestpackageid} settestSn={settestSn} settesttype={settesttype} settestDuration={settestDuration} settestmarks={settestmarks} settestQuestion={settestQuestion} settestAccesscode={settestAccesscode} settestTitle={settestTitle} />} />
            <Route path='/blog/:blogurl' element={<Dynamicblog />} />
        
            {/* TestResultDetailView?customName=J7TkmbUnIHSyPl4bdNUo */}
            <Route path='/TestResultDetailView' element={<Reportcard1 testTitle={testTitle} testpackageid={testpackageid} sessionid={sessionid} authtoken={authtoken} prevPath={prevPath} coursetopicsn={coursetopicsn} coursesectionId={coursesectionId} />} />
            <Route path='/course-lesson-result' element={<Reportcard />} />
            {/* <Route path='/CourseDescreption/CourseReattemptedResult' element={<Reportcardreattempted topictitle={topictitle} setreattemptedcourse={setreattemptedcourse} setcoursestatus={setcoursestatus} authtoken={authtoken} prevPath={prevPath} coursetopicsn={coursetopicsn} coursesectionId={coursesectionId}/>} /> */}
            <Route path='/blogs-today-in-history/detail/:todayurl' element={<DynamicToday />} />
            <Route path='/Registration' element={<Signup setIsLoggedIn={setIsLoggedIn} setispayment={setispayment} setAuthtoken={setAuthtoken} prevPath={prevPath} />} />
            <Route path='/contact-us' element={<Contact authtoken={authtoken} />} />
            <Route path="/paymentsuccess" element={<PaymentSuccess />} />
            <Route path="/testseries/paymentsuccess" element={<PaymentSuccesstest TestTitle1={TestTitle1} paymentdetail={paymentdetailtest} />} />
            <Route path='/testseries/finalresult' element={<Finalresult />} />
            <Route path='/teststart/:courseId/:testSeriesSn/:packageId/:emailId?' element={<TestStart />} />
            <Route path='/testseriesstart/:packageid/' element={<QuestionLayoutTestseries />} />
            <Route path='/testseriescontinue/:packageid/:packageid' element={<QuestionLayoutTestseriesContinue prevPath={prevPath} teststatus={teststatus} testSn={testSn} sessionid={sessionid} setsessionid={setsessionid} testQuestion={testQuestion} testDuration={testDuration} testpackageid={testpackageid} authtoken={authtoken} setPositiveScore={setPositiveScore} setNegativeScore={setNegativeScore} setcorrectdataarray={setcorrectdataarray} setincorrectdataarray={setincorrectdataarray} PositiveScore={PositiveScore} NegativeScore={NegativeScore} correctdataarray={correctdataarray} incorrectdataarray={incorrectdataarray} />} />
            <Route path='/terms-and-conditions' element={<Terms pageName='Terms and Conditions<' />} />
            <Route path='/privacy-policy' element={<Terms pageName='Privacy Policy' />} />
            <Route path='/StudentDashboard' element={isLoggedIn ? <Dashborad ispayment={ispayment} setispayment={setispayment} setcoursepayment={setcoursepayment} setcoursenotpayment={setcoursenotpayment} authtoken={authtoken} settestpackageid={settestpackageid} settesttype={settesttype} settestSn={settestSn} settestDuration={settestDuration} settestmarks={settestmarks} settestQuestion={settestQuestion} settestAccesscode={settestAccesscode} settestTitle={settestTitle} /> : <Navigate to="/login" />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
      {/* <div className='bg-dark'>
        
      </div> */}
    </HelmetProvider>
  );
}

export default App;