import React, { useState, useEffect } from 'react';
import "./CivilButton.css";
import CivilFeature from '../CivilFeature/CivilFeature';
import CivilToggle from '../CivilToggle/CivilToggle';
import CivilCard from '../CivilCard/CivilCard';
import CivilPop from '../CivilPop/CivilPop';
import CivilOverview from '../CivilOverview/CivilOverview';
import Toggle from '../CivilTestToggle/CivilTestToggle';
import CivilSchedule from '../CivilSchedule/CivilSchedule';
import Setting from '../../../Settings/Setting';
import Security from '../../../Security/Security';
import Mycourse from '../../../Mycourse/MyCourses';
import Subscribemore from '../../../Subscribemore/Subscribemore';
import Mytest from '../../../Mytest/Mytest';
import Moretest from '../../../Moretest/Moretest';
import ClassQuestion from '../../../ClassQuestion/ClassQuestion';
import Prelim from '../../../Prelims/Prelims';
import { useQuizGoingOn } from '../../../Context/QuizGoingOn';
import BookSubjects from '../../../Books/BookSubjects';
import { useNavigate } from 'react-router-dom';
import BookTestsAccordion from '../../../Books/BookTestsAccordion';

// only recieving/using moduleName when redirecting to dashboard after payment success
function CivilButton({ testSeriesData, courseData, newsData, lessonData, bookData, moduleName, componentName, heading, userDtails }) {
    const [teacher, SetTeacher] = useState(false);
    const [upload, SetUpload] = useState(false);
    const [activeButton, setActiveButton] = useState(''); //Track active button
    const [buttonNames, setButtonNames] = useState([]); //Manage All button Name Array
    const [isQuizGoingOn, setIsQuizGoingOn] = useQuizGoingOn();
    const navigate = useNavigate();
    // console.log(lessonData)
    // console.log(courseData)
    console.log(moduleName)
    const selectButton = () => {
        if (moduleName) {
            if (moduleName == 'course') {
                return 2;
            } else {
                return 4;
            }
        } else {
            return 2;
        }
    }



    const ctype1 = "question"
    const ctype2 = "upload"
    useEffect(() => {
        // Map component names to button labels
        const buttonLabels = {
            'civilHistory': ['Features', 'Classes', 'Current Developments', 'Teacher Support', 'Upload Answers'],
            'civilTest': ['Overview', 'Test', 'Schedule', 'Teacher Support'],
            'dashboard': ['Settings', 'Security', 'My Courses', 'Subscribe More Courses', 'My Test Series', 'Subscribe More Test Series'],
            'CourseDescriptionprelimstest': ['Class Questions', 'Prelims', 'Teacher Support', 'Upload Answers'],
            'CourseDescription': ['Class Questions', 'Teacher Support', 'Upload Answers'],
            'PrelimsDescription': ['Prelims', 'Teacher Support', 'Upload Answers'],
            'WithoutPrelimsDescription': ['Teacher Support', 'Upload Answers'],
            'book': ['Book', 'Current Developments', 'Teacher Support', 'Practice Test'],
            'bookTestSeries': ['Overview', 'Tests']
            // Add more component names and their associated button labels as needed
        };
        const defaultButtons = buttonLabels['civilHistory']; // Default to civilHistory if componentName doesn't match
        const buttons = buttonLabels[componentName] || defaultButtons;
        // Manage Default State of Feature and Overview Content According to ComponentName
        switch (componentName) {
            case 'civilHistory':
                setActiveButton(buttons[1].toLowerCase().replace(/\s+/g, ''));
                break;
            case 'civilTest':
                setActiveButton(buttons[1].toLowerCase().replace(/\s+/g, ''));
                break;
            case 'dashboard':
                setActiveButton(buttons[selectButton()].toLowerCase().replace(/\s+/g, ''));
                break;
            case 'CourseDescription':
                setActiveButton(buttons[0].toLowerCase().replace(/\s+/g, ''));
                break;
            case 'CourseDescriptionprelimstest':
                setActiveButton(buttons[1].toLowerCase().replace(/\s+/g, ''));
                break;
            case 'PrelimsDescription':
                setActiveButton(buttons[0].toLowerCase().replace(/\s+/g, ''));
                break;
            case 'WithoutPrelimsDescription':
                SetTeacher(true);
                setActiveButton(buttons[0].toLowerCase().replace(/\s+/g, ''));
                break;
            case 'book':
                SetTeacher(true);
                setActiveButton(buttons[0].toLowerCase().replace(/\s+/g, ''));
                break;
            case 'bookTestSeries':
                SetTeacher(true);
                setActiveButton(buttons[1].toLowerCase().replace(/\s+/g, ''));
                break;
            default:
                break;
        }
        setButtonNames(buttons);
    }, [componentName]);
    const handleButtonClick = (buttonName) => {
        if (buttonName.toLowerCase().replace(/\s+/g, '') == 'practicetest') {
            navigate(`/books/test-series/${bookData.urlTitle}`)
            return;
        }
        console.log(buttonName.toLowerCase().replace(/\s+/g, ''))
        setActiveButton(buttonName.toLowerCase().replace(/\s+/g, ''));

    };

    return (
        <>
            {/* Civil Button Section Start */}
            <div className='Civilbutton-background'>
                <div className='p-3 bg-gray'>
                    <div className="civilbutton-Container">
                        {buttonNames.map((name, index) => (
                            <button
                                key={index}
                                disabled={(name == 'Teacher Support' || name == 'Upload Answers' || name == 'Class Questions') && isQuizGoingOn}
                                className={`btn me-2 ${activeButton === name.toLowerCase().replace(/\s+/g, '') ? 'activebutton' : "bg-white"}`}
                                onClick={
                                    () => {
                                        handleButtonClick(name)
                                        switch (name) {
                                            case 'Teacher Support':
                                                // Handle Teacher Support accordingly
                                                SetTeacher(true)
                                                SetUpload(false)
                                                break;
                                            case 'Upload Answers':
                                                // Handle Upload Answers accordingly
                                                SetTeacher(false)
                                                SetUpload(true)
                                                break;
                                            default:
                                                break;
                                        }
                                    }
                                }
                                type="button">{name}</button>
                        ))}
                    </div>

                </div>
                {/* Display Information Section Start */}
                <div className='Civilinfo-display'>
                    {/* ************************Civil History Component Start************************************ */}
                    {/* Civil Feature Section Start */}
                    {activeButton === 'features' && <div className='px-4 bg-cream'><CivilFeature paragraphs={courseData.Description} /></div>}
                    {/* Civil Feature Section  End */}
                    {/* Civil Toggle Section Start  */}
                    {activeButton === 'classes' && <div className=''><CivilToggle courseData={courseData} /></div>}
                    {activeButton === 'book' && <div className=''><BookSubjects bookData={bookData} /></div>}
                    {activeButton === 'tests' && <BookTestsAccordion bookData={bookData} />}
                    {/* Civil Toggle Section End */}
                    {/* Civil Card Section Start */}
                    {activeButton === 'currentdevelopments' && <div className='px-4 ps-4'><CivilCard CardData={newsData} /></div>}
                    {/* Civil Card Section End */}
                    {/* Civil Pop Section Start */}
                    {activeButton === 'teachersupport' && <CivilPop isEnrolled={courseData?.isEnrolled || testSeriesData?.isEnrolled || 'enrolled'} ctype1={ctype1} ctype2={''} isteacher={teacher} iscourseid={courseData?.CourseID || testSeriesData?.CourseID} title={lessonData?.Title || courseData?.CourseTitle || testSeriesData?.Title} />}

                    {activeButton === 'uploadanswers' && <CivilPop isEnrolled={courseData?.isEnrolled} ctype1={''} ctype2={ctype2} isupload={upload} title={lessonData?.Title || courseData?.CourseTitle || testSeriesData?.Title} iscourseid={courseData?.CourseID || testSeriesData?.CourseID} />}
                    {/* Civil Pop Section End */}
                    {/* *************************Civil History Component End*************************************** */}
                    {/* *************************Civil Test Component Start**************************************** */}
                    {/* Civil Overview Section Start */}
                    {activeButton === 'overview' && <CivilOverview paragraphs={testSeriesData?.Description || bookData?.description} />}
                    {/* Civil Overview Section End */}
                    {/* Toggle Section Start */}
                    {activeButton === 'test' && <Toggle testSeriesData={testSeriesData} />}
                    {/* Toggle Section End */}
                    {/* Civil Schedule Section Start */}
                    {activeButton === 'schedule' && <CivilSchedule testSeriesData={testSeriesData} />}
                    {/* Civil Schedule Section End */}
                    {/* *************************Civil Test Component End*************************************** */}
                    {/* *************************Dashboard Component Start**************************************** */}
                    {/*Settings Section Start */}
                    {activeButton === 'settings' && <Setting userDtails={userDtails} />}
                    {/*Settings Section End */}
                    {/*Security Section Start */}
                    {activeButton === 'security' && <Security />}
                    {/*Security Section End */}
                    {/*My Courses Section Start */}
                    {activeButton === 'mycourses' && <Mycourse heading={heading} />}
                    {/*My Courses Section End */}
                    {/*Subscribe More Courses Section Start */}
                    {activeButton === 'subscribemorecourses' && <Subscribemore heading={heading} />}
                    {/*Subscribe More Courses Section End */}
                    {/*My Test Series Section Start */}
                    {activeButton === 'mytestseries' && <Mytest heading2={heading} />}
                    {/*My Test Series Section End */}
                    {/*Subscribe More Test Series Section Start */}
                    {activeButton === 'subscribemoretestseries' && <Moretest heading2={heading} />}
                    {/*Subscribe More Test Series Section End */}
                    {/* *************************Dashboard Component End*************************************** */}
                    {/* *************************Course Description Component Start**************************************** */}
                    {/*Settings Section Start */}
                    {activeButton === 'classquestions' && <ClassQuestion paragraphs={lessonData?.Description} />}
                    {/*Settings Section End */}
                    {/* *************************Course Description Component End*************************************** */}
                    {/* *************************Prelims Component Start**************************************** */}
                    {/*Settings Section Start */}
                    {activeButton === 'prelims' && <Prelim lessonData={lessonData} courseData={courseData} />}
                    {/*Settings Section End */}
                    {/* *************************Prelims Component End*************************************** */}
                </div>
                {/* Display Information Section End */}
            </div>
            {/* Civil Button Section End */}
        </>
    )
}

export default CivilButton;