import React from 'react';
import "./ContentCard.css";
import { Link } from 'react-router-dom';
import bali from "../../Image/bali.jpeg"
import i1 from "../../Image/civilonline.png"
import i2 from "../../Image/civiloffline.png"
import i3 from "../../Image/civilopt.png"
import i4 from "../../Image/civilgshist.png"

function ContentCard() {
  return (
    <>
      {/* Content Section Start */}
      <div className='ContentContainer Content-background' >
        <div className='ContentBox mobile-nav'>
          <div className='Content_left'>
            <div className="mb-2 p-3">
              <h1 className='heading-color'>Welcome to India's Most Trusted "UPSC Coaching Institute" (Insight Delhi)</h1>
              <hr />
              <p className='text-align'>When it comes to preparing for the UPSC exam, Insight Delhi, IAS stands out as the premier destination for aspirants seeking excellence. Here's why we are the top choice for General Studies and History Optional coaching for UPSC/IAS Examination in Delhi:</p>
              <p className='text-align'>We have a rich history of providing quality education to students aspiring to crack the UPSC CSE Examination. Our institute, located in the heart of Delhi at Old Rajinder Nagar, offers easy accessibility for students from all corners of the city. At <strong>Insight IAS</strong>, we provide state-of-the-art facilities and infrastructure conducive to learning. Our classrooms are equipped with modern amenities to ensure a conducive learning environment. Our institute is home to highly experienced faculties who are dedicated to helping aspirants succeed in the world's toughest examination. Their expertise and guidance play a pivotal role in shaping the future of our students.</p>
              <p className='text-align'>Students who have undergone coaching at Insight IAS consistently provide positive feedback about the quality of teaching and course materials. Our mock tests, updated study material, and regular mentorship sessions have garnered acclaim among aspirants. The phenomenal success of Insight Academy is evidenced by the achievements of our alumni, including Mallika Sudhir, Gaurav Agarwal, Ashish Kumar, and many others. Their high rankings in the UPSC Civil Services Examination are a testament to our commitment to excellence. Our UPSC Prelims Test Series - <strong>Insight GS PT Master for Prelims 2024-25</strong> is designed to simulate actual exam conditions. With detailed performance analysis and discussion sessions, we ensure that our students are well-prepared to excel in the examination.</p>
              <p className='text-align'>Insight IAS pays special attention to current national and international developments, crucial for cracking competitive exams like UPSC. We provide our students with the most updated <strong>current affaits for UPSC</strong> to keep them ahead of the curve.</p>
              <p className='text-align'>For the best UPSC coaching in Delhi, look no further than Insight IAS. We pride ourselves on being the top choice for general studies coaching in Delhi, offering comprehensive and effective preparation for the IAS exams. As a leading IAS coaching institute in Delhi, our focus extends to providing exceptional <strong>coaching for history optional</strong> subjects, ensuring that our students are well-equipped to excel in this crucial section of the UPSC exams. At Insight Delhi, we are committed to delivering unparalleled coaching, guiding aspirants on their journey to success in the field of civil services. Join us for the finest IAS coaching in Delhi and experience the difference that Insight Delhi can make in your UPSC exam preparation.</p>
              <div className='container-fluid pt-4'>
                <div className='row d-flex'>
                  <div className='col-lg-4 col-md-12 col-sm-12'>
                    <img className='home-responsive-width pt-3 pb-2' src={bali} alt='...' />
                    <p className='text-align text-lineheight fw-bold'>Shri. S. Baliyan</p>
                    <p className='text-align text-lineheight fw-bold'>Founder-Director</p>
                    <p className='text-align text-lineheight fw-bold'>Insight IAS Academy, Delhi</p>
                  </div>
                  <div className='col-lg-8 col-md-12 col-sm-12'>
                    <h1 className='heading-color' style={{ textAlign: "left" }}>Director's Message</h1>
                    <p className='text-align' style={{ color: "#0033FF", fontWeight: "600" }}>Dear Aspirants,</p>
                    <p className='text-align'>It gives me immense pleasure to welcome you to Insight Academy, Delhi, where excellence meets dedication in the realm of UPSC preparation. As the Founder-Director, I take great pride in <strong>our legacy of producing successful IAS toppers</strong> and serving the nation with integrity and commitment. At Insight Academy, we believe in a holistic approach to learning, focusing on building strong foundations through concept-based classes and regular mentor interactions. Our commitment to quality education is reflected in the success stories of our esteemed alumni, who have topped the UPSC Civil Services Examination and are now serving the nation with devotion.</p>
                    <p className='text-align'>I am honored to have authored <strong>'A Compendium of Indian Art & Culture'</strong>, a testament to our expertise in teaching History and Art and Culture, crucial components of the UPSC syllabus. In today's dynamic examination landscape, we understand the importance of adapting to changing patterns and trends. That's why we emphasize regular practice tests and hone answer writing skills, essential for excelling in the Mains Examination. Furthermore, our focus on current national and international developments ensures that our students stay ahead of the curve. With seminars and lectures by senior civil servants and former UPSC members, we instill the ethics and values integral to Indian Civil Services.</p>
                    <p className='text-align'>It brings me great satisfaction to share that Insight students are making their mark across more than 150 districts in India, serving as District Collectors. This accomplishment speaks volumes about our commitment to nurturing leaders who positively impact society. For those seeking the <strong>best UPSC coaching in Delhi,</strong> Insight Academy stands as a beacon of excellence. Our dedication to quality education, personalized mentorship, and updated curriculum sets us apart.</p>
                    <p className='text-align'>Join us at Insight Academy, where your aspirations meet expert guidance, and together, we pave the path to success.</p>
                  </div>
                </div>
              </div>
              <h1 className='heading-color'>Best UPSC History Optional Coaching In Delhi, India(Insight Delhi)</h1>
              <hr />
              <p className='text-align'>Elevate your UPSC History Optional preparation with Insight Delhi, recognized as the <strong>top UPSC History Optional coaching in Delhi,</strong> India. Our comprehensive test series, including GS Prelims, GS Pre Cum Mains, and specialized History Optional, ensures thorough readiness for success. Choose Insight Delhi for History Optional coaching, available both online and offline, with a focus on detailed history maps, GS History Optional, and cultural enrichment.</p>
              <p className='text-align'>Delve into our General Studies courses, featuring GS Foundation, GS Prelims, GS Mains, and Ethics Aptitude Integrity, meticulously designed to elevate your preparation to new heights. As the top IAS coaching in Delhi, we are committed to providing unparalleled coaching and ensuring our students excel in the UPSC exams. Join Insight Delhi for the <strong>best history optional coaching in Delhi</strong> and a transformative learning experience that enhances your chances of success in the civil services arena.</p>
              <div className='container-fluid'>
                <div className='row d-flex'>
                  <Link to="/course/History-Optional-Online" className='col-lg-3 col-md-12 col-sm-12 text-center list-group-item'>
                    <img src={i1} className='home-responsive-width1' alt='' />
                    <p className='text-align' style={{ textAlign: "center", fontWeight: "600", fontSize: "15px" }}>UPSC History Optional Online Coaching</p>
                  </Link>
                  <Link to="/course/History-Optional-Offline" className='col-lg-3 col-md-12 col-sm-12 text-center list-group-item'>
                    <img src={i2} className='home-responsive-width1' alt='' />
                    <p className='text-align' style={{ textAlign: "center", fontWeight: "600", fontSize: "15px" }}>UPSC History Optional Offline Coaching</p>
                  </Link>
                  <Link to="/course/History-and-Culture" className='col-lg-3 col-md-12 col-sm-12 text-center list-group-item'>
                    <img src={i4} className='home-responsive-width1' alt='' />
                    <p className='text-align' style={{ textAlign: "center", fontWeight: "600", fontSize: "15px" }}>UPSC History & Culture Coaching</p>
                  </Link>
                  <Link to="/course/history-optional" className='col-lg-3 col-md-12 col-sm-12 text-center list-group-item'>
                    <img src={i3} className='home-responsive-width1' alt='' />
                    <p className='text-align' style={{ textAlign: "center", fontWeight: "600", fontSize: "15px" }}>UPSC History Optional Map Coaching</p>
                  </Link>
                </div>
              </div>
              <h1 className='heading-color'>Why Choose Insight Delhi as your UPSC Preparation partner</h1>
              <p className='text-align' style={{ textAlign: "center" }}>Leading UPSC Coaching Academy in Delhi, India</p>
              <hr />
              <p className='text-align'><strong>Expert Guidance:</strong> Led by Shri S. Baliyan, a renowned figure in the field of UPSC preparation, INSIGHT Academy offers expert guidance from experienced faculty members who have a deep understanding of the exam pattern and requirements.</p>
              <p className='text-align'><strong>Proven Track Record:</strong> The academy boasts a remarkable track record with numerous successful candidates who have topped the UPSC Civil Services Examination. The success stories of Mallika Sudhir, Gaurav Agarwal, and others are a testament to the effectiveness of INSIGHT's approach.</p>
              <p className='text-align'><strong>Holistic Learning Approach:</strong> INSIGHT adopts a holistic approach to learning, focusing on building solid foundations through concept building classes and regular interaction with mentors. This ensures that students develop a comprehensive understanding of the subjects.</p>
              <p className='text-align'><strong>Regular Practice Tests:</strong> With the changing pattern of exams, INSIGHT emphasizes the importance of regular practice tests. These tests help students develop essential answer writing skills, which are crucial for scoring well in the Mains Examination.</p>
              <p className='text-align'><strong>Current Affairs Integration:</strong> Keeping abreast of current national and international developments is essential for cracking competitive exams like UPSC. INSIGHT ensures that students stay updated with the latest happenings through dedicated classes on current affairs.</p>
              <p className='text-align'><strong>Interactive Sessions and Seminars:</strong> The academy organizes seminars and lectures by senior civil servants and former UPSC members to keep aspirants motivated and imbibe the ethics of Indian Civil Services. Such interactive sessions provide valuable insights and guidance to the students.</p>
              <p className='text-align'><strong>Affordable Fee Structure:</strong> Insight Delhi offers an affordable fee structure, making quality UPSC coaching accessible to a wide range of aspiring civil servants. With a commitment to providing excellent guidance at reasonable costs, INSIGHT ensures that students receive top-notch preparation without financial barriers hindering their aspirations.</p>
            </div>
          </div>
        </div>
      </div>
      {/* Content Section End */}
    </>
  )
}

export default ContentCard;